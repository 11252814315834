/* eslint-disable-next-line no-unsafe-optional-chaining */
import React from "react";
import TeamHistory from "./TeamHistory";
import {formatValue} from "../utils";
import InfoItem from "../../../afterLogin/PostBookingConfirmation/components/InfoItemUI";

function LeftSidebar(props) {
  const entriesArray = Object.entries(props.profile).reduce(
    (acc, [key, value]) => {
      const formattedValue = formatValue(
        value?.answer?.value,
        value?.fieldType
      );
      if (formattedValue && formattedValue.length > 8) {
        acc.typeGreater.push({
          id: key,
          questionName: value?.questionName,
          answer: formattedValue,
          type: "greater",
          qCreatedAt: new Date(value?.qCreatedAt),
        });
      } else {
        acc.typeLess.push({
          id: key,
          questionName: value?.questionName,
          answer: formattedValue,
          type: "less",
          qCreatedAt: new Date(value?.qCreatedAt),
        });
      }
      return acc;
    },
    { typeGreater: [], typeLess: [] }
  );

  const mergedArray = entriesArray.typeLess.concat(entriesArray.typeGreater);
  mergedArray.sort((a, b) => b.qCreatedAt - a.qCreatedAt);

  return (
    <>
      <div className="athlete card-row">
        <div className="person-details">
          {mergedArray.map((item) => (
            <InfoItem
              key={item.id}
              title={item.questionName}
              desc={item.answer}
            />
          ))}
          <div className="team-history">
            <h4 className="mt-3">Company Profile</h4>
            <TeamHistory company={props.company} />
          </div>
        </div>
      </div>
    </>
  );
}

export default LeftSidebar;
