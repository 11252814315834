// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistCombineReducers } from "redux-persist";

import authReducer from "./authReducer";

import bookingReducer from "./bookingReducer";
import notificationReducer from "./notificationReducer";
import checkinReducer from "./checkinReducer";
import signupReducer from "./signupReducer";
import publicUserReducer from "./publicBookingReducer";
import athleteProfileReducer from "./athleteProfileReducer";
import localforage from "localforage";

const persistConfig = {
  key: "root",
  storage: localforage,
  whitelist: ["auth", "notification", "publicUser"],
};

const rootReducer = persistCombineReducers(persistConfig, {
  auth: authReducer,
  booking: bookingReducer,
  notification: notificationReducer,
  publicUser: publicUserReducer,
  checkin: checkinReducer,
  signup: signupReducer,
  athleteProfile: athleteProfileReducer,
});

export default rootReducer;
