import {
  NOTIFICATION_FETCHING,
  NOTIFICATION_FETCH_COMPLETE,
} from "../actionTypes";

const INITIAL_STATE = {
  notifications: [],
  loading: false,
  count: 0,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_FETCHING:
      return { ...state, loading: true };
    case NOTIFICATION_FETCH_COMPLETE:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
        count: action.count,
      };
    default:
      return state;
  }
};

export default reducer;
