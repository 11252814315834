import { Steps } from "antd";
import React from "react";
import { campStepsArr } from "../../common/config";
import "./CampSteps.scss";

export default function CampSteps({ current }) {
  return (
    <Steps
      // progressDot
      labelPlacement="vertical"
      className={"ant_extra"}
      current={current}
      items={campStepsArr}
    />
  );
}
