import { validateEmail } from "../../../utility/helperFunctions";
import { getPMAge } from "../../../utility/helperFunctions";

export const SIGNUP_FIELDS = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  DOB: "dob",
  PASSWORD: "password",
  CON_PASSWORD: "confirmPassword",
  PHONE: "phone",
  EMAIL: "email",
  GENDER: "gender",
  FAMILY_MEM: "familyMembers",
  //! extra | not in payload
  TNC_TERMS: "tncTerms",
  TNC_PRIVACY: "tncPrivacy",
  TNC_18_PLUS: "tnc18Plus",
};

export const TYPE_OF_SIGNUP = {
  ONLY_YOU: "Only You",
  FAMILY_MEMBERS: "Your family member(s)",
  YOU_AND_FAMILY_MEMBERS: "You + Your family member(s)",
};

export const SIGNUP_SCREENS = {
  FM: "FM",
  SIGNUP_MAIN: "SIGNUP_MAIN",
  ADD_MEMBER_NAME: "ADD_MEMBER_NAME",
  ADD_FM_NAME: "ADD_FM_NAME",
  DEFAULT: "DEFAULT", //type of signup
};

export const familyMemberFieldDefaultProps = {
  [SIGNUP_FIELDS.FIRST_NAME]: "",
  [SIGNUP_FIELDS.LAST_NAME]: "",
  [SIGNUP_FIELDS.DOB]: "",
  [SIGNUP_FIELDS.PHONE]: "",
  [SIGNUP_FIELDS.EMAIL]: "",
  [SIGNUP_FIELDS.PASSWORD]: "",
  [SIGNUP_FIELDS.CON_PASSWORD]: "",
  [SIGNUP_FIELDS.DOB]: null,
  [SIGNUP_FIELDS.FAMILY_MEM]: [],
  [SIGNUP_FIELDS.TNC_18_PLUS]: false,
  [SIGNUP_FIELDS.TNC_PRIVACY]: false,
  [SIGNUP_FIELDS.TNC_TERMS]: false,
};

export const isSignUpMainFieldsValid = (initialInputDetails) => {
  if (
    initialInputDetails?.firstName?.trim() &&
    initialInputDetails?.lastName?.trim() &&
    initialInputDetails?.email?.trim() &&
    initialInputDetails?.password?.trim() &&
    initialInputDetails?.confirmPassword?.trim() &&
    validateEmail(initialInputDetails?.email?.trim()) &&
    getPMAge(initialInputDetails?.dob) >= 13 &&
    initialInputDetails?.phone?.trim() &&
    initialInputDetails?.password?.length >= 8 &&
    initialInputDetails?.password === initialInputDetails?.confirmPassword
  ) {
    return true;
  }
  return false;
};

export const isSignUpFmFieldsValid = (childDetailsList) => {
  const found = childDetailsList?.find(
    (item) =>
      !item?.firstName?.trim() || !item?.lastName?.trim() || !item?.dob?.trim()
  );
  if (!found) {
    return true;
  }
  return false;
};
