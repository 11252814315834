import PerformanceTab from "./PerformanceTab";
import React, { useEffect, useState } from "react";
import { Tabs, Tab, Container, Row, Col } from "react-bootstrap";
import VideosTab from "./VideosTab";
import LeftSidebar from "./LeftSidebar";

const TabsContainer = (data) => {
  const [loginType, setLoginType] = useState("performance");
  useEffect(() => {
    if (data?.data?.tabType === "video") {
      setLoginType("video");
    }
  }, [data]);

  return (
    <div className="sign_in mb-3 performance-tab">
      <div className="pt-0">
        <div className="p-2">
          <form>
            <Container>
              <Row>
                <Col md={5} className="mobile-hide mt-5">
                  <LeftSidebar
                    profile={data?.data?.profile}
                    company={data?.data?.company}
                  />
                </Col>
                <Col md={7} className="width-100">
                  <Tabs
                    onSelect={(txt) => setLoginType(txt)}
                    //defaultActiveKey={"performance"}
                    id="uncontrolled-tab-example"
                    activeKey={loginType}
                  >
                    <Tab eventKey={"performance"} title="Performance">
                      <Col>
                        <PerformanceTab />
                      </Col>
                    </Tab>
                    <Tab eventKey={"video"} title="Videos">
                      <Col>
                        <VideosTab />
                      </Col>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Container>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TabsContainer;
