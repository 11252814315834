import moment from "moment-timezone";
import React from "react";
import {Card, Button} from "react-bootstrap";
import {IoIosShareAlt} from "react-icons/io";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {ATHLETE_PROFILE_SHARE_MODAL_TYPES} from "../utils";
import {showShareModal} from "../../../../redux/actions/athleteProfileActions";
import {useSelector} from "react-redux";

const VideoTabData = ({video, index}) => {
  const isSharedVideo = useSelector(
    (state) => state?.athleteProfile?.details?.isSharedVideo
  );
  const dispatch = useDispatch();
  const isFirstVideo = index === 0 && isSharedVideo;
  return (
    <>
      <Card
        className={`athlete card mt-3 ${
          isFirstVideo ? "highlighted-video" : ""
        }`}
      >
        <Card.Header>
          <div className="card-head-cont w-100">
            <Card.Title className="headTitle ml-1">
              <b>{video?.name}</b>
            </Card.Title>
            {video?.date && (
              <Card.Text className="ml-4 mt-1">
                {moment(video?.date).format("MM/DD/YYYY")}
              </Card.Text>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {video?.type === "video" ? (
            <video width="750" height="500" controls className="videoTab">
              <source src={video?.url} type="video/mp4" />
            </video>
          ) : (
            <p className="text font-size-14 ml-3">
              <Link
                className="text ml-4"
                to={{pathname: video?.url}}
                target="_blank"
              >
                {video?.domain
                  ? "Click here to view video on " + video?.domain
                  : "Click here"}
              </Link>
            </p>
          )}
          <Button
            variant="light btn share-btn"
            onClick={() =>
              dispatch(
                showShareModal(
                  ATHLETE_PROFILE_SHARE_MODAL_TYPES.VIDEO,
                  location.href + "&videoId=" + video?.id,
                  video?.name
                )
              )
            }
          >
            <IoIosShareAlt size={20} className="mr-1" />
            Share
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default VideoTabData;
