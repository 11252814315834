import { Collapse, Divider } from "antd";
import moment from "moment";
import { calendar } from "../../../assets/imageDir";

const RepeatClassesUI = ({ repeatIdClasses, repeatId }) => {
  const collapseItems = repeatIdClasses?.map((classItem) => ({
    key: classItem?.uuid,
    label: (
      <div className="date-time-div mb-3">
        <div className="date-div">
          <span className="_icon">
            <img src={calendar} alt="" />
          </span>
          {moment(classItem?.start_branch_time).format("dddd, MMMM Do YYYY")}
        </div>
        <div className="time-div">
          <span className="_icon">
            <i className="far fa-clock"></i>
          </span>
          {moment(classItem?.start_branch_time).format("hh:mm A")}
        </div>
        <Divider
          type="vertical"
          style={{ marginRight: "-10px", marginLeft: "-10px" }}
        />
        <div>{classItem?.serviceName}</div>
      </div>
    ),
    children: <pre className="desc-normal">{classItem.description}</pre>,
  }));

  if (repeatId) {
    return (
      <>
        <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
          All Event Days
        </p>
        <Collapse items={collapseItems} bordered={false} />
      </>
    );
  } else {
    return null;
  }
};

export default RepeatClassesUI;
