import axios from "axios";
import { NODE_LAMBDA } from "../../utility/constants";
import {
  NOTIFICATION_FETCHING,
  NOTIFICATION_FETCH_COMPLETE,
} from "../actionTypes";

export const getNotifications = (id) => {
  return (dispatch) => {
    try {
      dispatch({ type: NOTIFICATION_FETCHING });
      axios({
        method: "post",
        url: NODE_LAMBDA + "getNotifications",
        data: {
          receiverId: id,
          isCleared: "false",
        },
      }).then((response) => {
        console.log("response notification", response.data);
        if (response.data.status) {
          const unRead = response.data.data.filter((e) => e.isRead === 0);
          dispatch({
            type: NOTIFICATION_FETCH_COMPLETE,
            payload: response.data.data,
            count: unRead.length,
          });
        } else {
          dispatch({
            type: NOTIFICATION_FETCH_COMPLETE,
            payload: [],
            count: 0,
          });
        }
      });
    } catch (error) {
      console.log("error notification", error.message);
      dispatch({
        type: NOTIFICATION_FETCH_COMPLETE,
        payload: [],
        count: 0,
      });
    }
  };
};
