import React from "react";
import { publicAppointmentBooking } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IMAGE_URL } from "../../../utility/constants";
import { getInitials } from "../../../utility/helperFunctions";

const PublicCoachesList = ({ trainerList, selectedService }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <ul className="_clientLists row">
      {trainerList?.length > 0 ? (
        trainerList.map((data) => (
          <li
            className="_clientList col _bookingTable-outter mb-3"
            key={data?.branchTrainersTrainerId}
          >
            <div className="_clientList-div  _rightArr">
              <span className="_proPic">
                {data?.imageUrl ? (
                  <img src={IMAGE_URL + data.imageUrl} alt="" />
                ) : (
                  <span className="avName">
                    <span className="proName">
                      {data.firstName &&
                        getInitials(data.firstName, data.lastName)}
                    </span>
                  </span>
                )}
              </span>
              <div className="_clCont col">
                <p className="_name">{data.trainerName}</p>
                <p className="_info">coach</p>
              </div>
              <button
                className="book_now btn btn-primary ml-4"
                onClick={() => {
                  dispatch(
                    publicAppointmentBooking({
                      service: selectedService,
                      trainer: data,
                    })
                  );
                  history.push("/login");
                }}
              >
                Book
              </button>
            </div>
          </li>
        ))
      ) : (
        <span className="font-size-20">
          No coaches are available for this selected location and selected
          service
        </span>
      )}
    </ul>
  );
};

export default PublicCoachesList;
