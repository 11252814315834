import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../utility/constants";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { publicBranchChange } from "../../redux/actions";
import useQuery from "../../hooks/useQuery";

const SignInHeader = ({ publicBooking }) => {
  const branchIdUrlQuery = useQuery().get("branchId");
  const sessionIdUrlQuery = useQuery().get("sessionId");
  const fromCheckInUrlQuery = useQuery().get("fromCheckIn");
  const authDetails = useSelector((state) => state.auth);
  const publicUser = useSelector((state) => state.publicUser);
  const [activeCompany, setActiveCompany] = useState(null);
  const [activeBranch, setActiveBranch] = useState(null);
  const dispatch = useDispatch();

  const loadBranchData = useCallback(() => {
    if (authDetails.companyData) {
      setActiveCompany(authDetails?.companyData);
      setActiveBranch(authDetails?.companyData?.branches?.items[0]);
      if (branchIdUrlQuery) {
        const branch =
          authDetails?.companyData?.branches?.items?.filter(
            (item) => item?.id === branchIdUrlQuery
          ) || [];
        setActiveBranch(branch[0]);
        dispatch(
          publicBranchChange({
            companyId: authDetails?.companyData?.id,
            branchSelected: branch[0],
            sessionId: sessionIdUrlQuery,
            isFromCheckIn: fromCheckInUrlQuery,
          })
        );
        return;
      }
      dispatch(
        publicBranchChange({
          companyId: authDetails?.companyData?.id,
          branchSelected: authDetails?.companyData?.branches?.items[0],
          sessionId: sessionIdUrlQuery,
          isFromCheckIn: fromCheckInUrlQuery,
        })
      );
    }
  }, [authDetails.companyData, branchIdUrlQuery, dispatch]);

  useEffect(() => {
    loadBranchData();
  }, [loadBranchData]);

  return (
    <header id="page-topbar">
      <nav className="navbar-header position-relative">
        <div className="cf-logo pl-4">
          {/* <Link to="/" className="logo">
            <img src={logoLight} alt="" width="70" />
          </Link> */}
        </div>
        {publicBooking && (
          <div className="external-login-hdr company_logo top-50 start-50">
            <Link to="/" className="logo logo-light">
              <img
                src={
                  IMAGE_URL +
                  (authDetails?.companyData?.imageUrl ||
                    publicUser?.companyImage)
                }
                alt=""
                className="img-fluid ex-logo"
              />
            </Link>
            <span className="text-dark text-truncate ml-3">
              {activeCompany?.name}
            </span>
          </div>
        )}
        <div className="external-login-hdr second-dropdown company_logo  top-50 start-50">
          <Dropdown>
            {/* <div className="row"> */}
            <div className="col-12">
              <div className="text-center">
                <div className="row d-inline-block">
                  <span className="text-dark text-truncate name">
                    {activeBranch?.name}
                  </span>
                  <Dropdown.Toggle id="company_dropdown" className="toggle">
                    <i
                      className="fas fa-chevron-down user-dropdown"
                      aria-hidden="true"
                    ></i>
                  </Dropdown.Toggle>
                </div>
              </div>
            </div>
            {/* </div> */}

            <Dropdown.Menu className="company dropdown-menu show py-4">
              {activeCompany?.branches?.items?.map((item, index) => (
                <Dropdown.Item
                  className="dropdown-item border-bottom p-4"
                  key={index}
                  onClick={() => {
                    dispatch(
                      publicBranchChange({
                        companyId: activeCompany?.id,
                        branchSelected: item,
                        sessionId: sessionIdUrlQuery,
                      })
                    );
                    setActiveBranch(item);
                  }}
                  active={activeBranch?.id === item?.id}
                >
                  <img
                    src={IMAGE_URL + item?.imageUrl}
                    alt=""
                    className="img-fluid ex-logo"
                    // height="30"
                    width="40"
                  />
                  <span className="ml-3">{item?.name}</span>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="d-flex d-none d-sm-block">
          <div className="_btnGrp text-center">
            <Link className="btn btn-primary" to="/login">
              Sign In / Sign Up
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default SignInHeader;
