import {
  CHECKIN_CLASS_DETAILS_FETCH_COMPLETED,
  CHECKIN_MODAL_HIDE,
  CHECKIN_SEARCH_MEMBER_STARTED,
  CHECKIN_MEMBER_TO_CLASS_STARTED,
  CHECKIN_MEMBER_TO_CLASS_FINISHED,
  CHECKIN_SEARCH_MEMBER_FINISHED,
  CHECKIN_DROP_IN_MEMBERS_STARTED,
  CHECKIN_DROP_IN_MEMBERS_FINISHED,
  CHECKIN_WAIVER_SHOW_MODAL,
  CHECKIN_WAIVER_SIGN_IN_STARTED,
  CHECKIN_WAIVER_SIGN_IN_FINISHED,
  CHECKIN_SHOW_SENT_REGISTRATION_EMAIL,
  CHECKIN_DROP_IN_WAIVER_SET_SELECTED_MEMBERS,
  CHECKIN_DROP_IN_SET_INPUT_EMAIL_OR_PHONE,
} from "../actionTypes";
import { showToast } from "../../utility/helperFunctions";
import {
  CHECKIN_MODAL_TYPES,
  apiFetchCheckinClassDetails,
  apiFetchMemberDetails,
  apiPostCheckin,
  apiPostCheckinDropinMembersToClass,
  apiPostSendEmail,
  apiPostSignWaiverAndCheckin,
} from "../../pages/Others/CheckIn/utils";

export const getCheckinClassDetails = (key) => async (dispatch) => {
  const response = await apiFetchCheckinClassDetails(key);
  if (response.status === 200) {
    dispatch({
      type: CHECKIN_CLASS_DETAILS_FETCH_COMPLETED,
      payload: { ...response.data, checkinUrlKey: key },
    });
    return;
  }
  showToast.error(response?.data?.message);
};

export const checkinClassAction = (key, data) => async (dispatch) => {
  if (!data?.isWaiverSigned) {
    dispatch({ type: CHECKIN_WAIVER_SHOW_MODAL, payload: data });
    return;
  }
  dispatch({ type: CHECKIN_MEMBER_TO_CLASS_STARTED });
  const response = await apiPostCheckin(key, data);
  if (response.status === 200) {
    showToast.success(response?.data?.message);
    dispatch({
      type: CHECKIN_MEMBER_TO_CLASS_FINISHED,
      payload: response.data,
    });
    //! close the model on checkin success after some time
    setTimeout(() => {
      dispatch({
        type: CHECKIN_MODAL_HIDE,
      });
    }, 5000);
    return;
  }
  showToast.error(response?.data?.message);
  dispatch({
    type: CHECKIN_MEMBER_TO_CLASS_FINISHED,
  });
};

export const hideCheckinModal = () => {
  return {
    type: CHECKIN_MODAL_HIDE,
  };
};

export const getMemberDetails = (payload) => async (dispatch) => {
  dispatch({ type: CHECKIN_SEARCH_MEMBER_STARTED });
  const response = await apiFetchMemberDetails(payload);
  if (response.status === 200) {
    dispatch({
      type: CHECKIN_SEARCH_MEMBER_FINISHED,
      payload: {
        alertTitle: `We located your account!`,
        type: CHECKIN_MODAL_TYPES.ACCOUNT_FOUND_TRUE,
        memberDetails: {
          member_packs: response.data?.member_packs,
          member: response.data?.member,
          classMember: response.data?.classMember,
          familyMembers: response.data?.familyMembers,
          isUnlimited: response.data?.isUnlimited,
          remaining_cnt: response.data?.remaining_cnt,
          isWaiverSigned: response.data?.isWaiverSigned,
        },
      },
    });
    return;
  }
  // showToast.error(response?.data?.message);
  dispatch({
    type: CHECKIN_SEARCH_MEMBER_FINISHED,
    payload: {
      alertTitle: `We were unable to locate an existing account associated with ${
        (payload?.phone && `+${payload?.phone}`) || payload?.email
      }`,
      type: CHECKIN_MODAL_TYPES.ACCOUNT_FOUND_FALSE,
    },
  });
};

export const checkinDropInMembersAction = (data) => async (dispatch) => {
  dispatch({ type: CHECKIN_DROP_IN_MEMBERS_STARTED });
  const response = await apiPostCheckinDropinMembersToClass(data);
  if (response.status === 200) {
    showToast.success(response?.data?.message);
    dispatch({
      type: CHECKIN_DROP_IN_MEMBERS_FINISHED,
      payload: {
        members: response?.data?.members,
        member_packs: response?.data?.member_packs,
        classRemainingSpots: response?.data?.classRemainingSpots,
      },
    });
    dispatch({
      type: CHECKIN_MODAL_HIDE,
    });
    return;
  }
  showToast.error(response?.data?.message);
};

export const checkinWaiverSignAction = (data) => async (dispatch) => {
  dispatch({ type: CHECKIN_WAIVER_SIGN_IN_STARTED });
  const response = await apiPostSignWaiverAndCheckin(data);
  if (response.status === 200) {
    showToast.success(response?.data?.message);
    dispatch({
      type: CHECKIN_WAIVER_SIGN_IN_FINISHED,
      payload: {
        members: response?.data?.members,
        //! also present in response -> remaining_cnt, isUnlimited, member_packs
      },
    });
    return;
  }
  showToast.error(response?.data?.message);
};

export const sendEmailRegistrationLinkAction =
  (payload) => async (dispatch) => {
    const response = await apiPostSendEmail(payload);
    if (response.status === 200) {
      // showToast.success(response?.data?.message);
      dispatch({
        type: CHECKIN_SHOW_SENT_REGISTRATION_EMAIL,
      });
      setTimeout(() => {
        dispatch({
          type: CHECKIN_MODAL_HIDE,
        });
      }, 7000);
      return;
    }
    showToast.error(response?.data?.message);
  };

export const showWaiverModalAction = () => {
  return { type: CHECKIN_WAIVER_SHOW_MODAL };
};

export const setSelectedDropinMembersAction = (payload) => {
  return { type: CHECKIN_DROP_IN_WAIVER_SET_SELECTED_MEMBERS, payload };
};
export const setEmailOrPhoneInputDropIn = (payload) => {
  return { type: CHECKIN_DROP_IN_SET_INPUT_EMAIL_OR_PHONE, payload };
};
