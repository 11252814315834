import { toast } from "react-toastify";
import PackUI from "./PackUI";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedPackage } from "../../../redux/actions";
import moment from "moment";
import {installmentsToDisplay, sortedInstallments} from "../utils";
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
const CampPacks = ({ selectedPackage, campPacksList, isInst }) => {
  const bookingDetails = useSelector((state) => state.booking);
  const {
    repeat_id,
    allow_book_individually,
    one_time_fee,
    start_branch_time,
  } = bookingDetails?.classDetails || {};
  const dispatch = useDispatch();
  console.log('selectedPackage', selectedPackage);

  //const sorted_installments = sortedInstallments(selectedPackage?.inst?.installments);
  const displayInstallments = installmentsToDisplay(selectedPackage?.inst?.installments);
  const [installmentsmodal, setInstallmentsmodal] = useState(false);

  return (
    <>
      {repeat_id && allow_book_individually && !isInst && (
        <>
          <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
            Please select if you would like to book all the days of the event or
            just the present day?
          </p>

          <div className="row row-cols-1 row-cols-md-4 mb-3 text-center">
            {campPacksList.map((pack) => (
              <PackUI
                {...pack}
                key={pack.label}
                _handleOnClick={() => dispatch(addSelectedPackage(pack))}
                isSelected={pack.campBookType === selectedPackage?.campBookType}
                isShowBookingDate={pack.campBookType === "single"}
                bookingDate={
                  // show date for the single pack if packs are showing for multi-day camps
                  moment(start_branch_time).format("MMMM Do")
                }
                coupon={bookingDetails?.couponCode?.couponDetails}
              />
            ))}
          </div>
          {one_time_fee && (
            <div className="pl-3">
              <input
                className="form-check-input"
                type="checkbox"
                id={`check1`}
                checked={selectedPackage?.isOneTimeMembershipFeeEnabled}
                onChange={() => {
                  if (!selectedPackage?.price) {
                    toast.error("You need to select at-least one package!");
                    dispatch(
                      addSelectedPackage({
                        ...selectedPackage,
                        isOneTimeMembershipFeeEnabled: false,
                      })
                    );
                    return;
                  }
                  dispatch(
                    addSelectedPackage({
                      ...selectedPackage,
                      isOneTimeMembershipFeeEnabled:
                        !selectedPackage?.isOneTimeMembershipFeeEnabled,
                    })
                  );
                }}
              />
              <p>
                There is a member fee of $ {one_time_fee}, please check this box
                if you have not paid the membership fee yet
              </p>
            </div>
          )}
        </>
      )}

      {!repeat_id && !isInst && (
        <>
          <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
            Packages
          </p>
          <div className="row row-cols-1 row-cols-md-4 mb-3 text-center">
            <PackUI
              {...selectedPackage}
              coupon={bookingDetails?.couponCode?.couponDetails}
            />
          </div>
        </>
      )}
      {repeat_id && !allow_book_individually && !isInst && (
        <>
          <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
            All the days of the event will be booked
          </p>
          <div className="row row-cols-1 row-cols-md-4 mb-3 text-center">
            <PackUI
              {...selectedPackage}
              coupon={bookingDetails?.couponCode?.couponDetails}
            />
          </div>
        </>
      )}
      {isInst && (
          <>
            <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
              Please select the package you would like to book
            </p>

            <div className="row row-cols-1 row-cols-md-4 mb-3 text-center">
              {campPacksList.map((pack) => (
                  <PackUI
                      {...pack}
                      isInst={isInst}
                      key={pack.label}
                      _handleOnClick={() => {
                        dispatch(addSelectedPackage(pack));
                        setInstallmentsmodal(true);
                      }
                      }
                      isSelected={pack.id === selectedPackage.id}
                      isShowBookingDate={false}
                      coupon={bookingDetails?.couponCode?.couponDetails}
                  />
              ))}
            </div>
            {
                selectedPackage?.installmentType === 'installment' && displayInstallments?.length > 0 &&
                !installmentsmodal &&
                <>
                  <div className="ml-auto mr-auto" style={{
                    width: "40%",
                    boxSizing: "border-box",
                    padding: "15px",
                    borderWidth: "2px",
                    borderStyle: "dashed",
                    borderColor: "red",
                    borderRadius: '10px',
                    backgroundColor: "#f9f9f9",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}>
                    <h3 className="text-center" style={{ color: "#333", marginBottom: "15px" }}>{selectedPackage?.inst?.name}</h3><br/>
                    <div className="font-weight-bold font-size-15"
                         style={{
                           marginBottom: "15px",
                           color: "#555",
                           fontSize: "16px"
                    }}>
                      Following installments will
                      be charged on the <br/> following dates:
                    </div>
                    {
                      displayInstallments?.map((installment, index) => (
                          <div key={index} className="font-size-14 lh-lg" style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "8px 0",
                            borderBottom: "1px solid #eee",
                            color: "#333"
                          }}>
                            <span>{installment.dt}</span>
                            <span className="text-black-100">${installment.price}</span>
                          </div>
                      ))
                    }
                  </div>
                </>
            }
            {selectedPackage?.installmentType === 'installment' && displayInstallments?.length > 0 &&
                <Modal
                    show={installmentsmodal}
                    onHide={() => setInstallmentsmodal(false)}>
                  <Modal.Header>
                    <h4 className="modal-title">{selectedPackage?.inst?.name}</h4>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={
                          () => {
                            setInstallmentsmodal(false)
                            dispatch(addSelectedPackage(null))
                          }
                        }
                    ></button>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="mx-auto" style={{width: "80%"}}>
                      <div className="font-weight-bold font-size-16" style={{marginBottom: "16px"}}>
                        Following installments will be charged on the following dates:
                      </div>
                      {displayInstallments?.map((installment, index) => (
                          <div
                              key={index}
                              className="font-size-14"
                              style={{
                                marginBottom: "8px",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "8px 0",
                                borderBottom: "1px solid #e0e0e0"
                              }}
                          >
                            <span>{installment.dt}</span>
                            <span className="text-black-100">${installment.price}</span>
                          </div>
                      ))}
                    </div><br/>
                    <div className="text-center mr-auto ml-auto"
                         style={{display: 'flex', justifyContent: 'center', gap: '15px'}}>
                      <button className="btn btn-outline-danger px-md-5" onClick={
                        () => {
                          setInstallmentsmodal(false)
                          dispatch(addSelectedPackage({}))
                          console.log('selectedPackage', selectedPackage)
                        }
                      }>Back
                      </button>
                      {!selectedPackage?.isSelectBtn &&
                          <button type="button" className="btn btn-primary px-5 font-size-15"
                                  onClick={() => {
                                    dispatch(
                                        addSelectedPackage({
                                          ...selectedPackage,
                                          isSelectBtn: true,
                                        })
                                    )
                                    setInstallmentsmodal(false)
                                  }}
                          >{selectedPackage?.isSelectBtn ? 'Installment Plan Selected' : 'Select'}</button>}
                      {false && selectedPackage?.isSelectBtn &&
                          <strong className="text-success font-size-20">Installment Plan Selected!</strong>
                      }
                    </div>
                  </Modal.Body>
                </Modal>
            }
          </>
      )
      }
    </>
  );
};

export default CampPacks;
