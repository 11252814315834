import React from "react";
import Header from "./Header";
// import Sidebar from "./Sidebar";

import Footer from "./Footer";
import ServiceSelect from "./ServiceSelect";
import SelectTrainer from "./SelectTrainer";
import SelectTimeSlot from "./SelectTimeSlot";
import ConfirmBooking from "./ConfirmBooking";
import SignInHeader from "./SignInHeader";
import ToastNotification from "./ToastNotification";
import Calendar from "./Calendar";

import DashboardEventsDetailsModal from "./DashboardEventsDetailsModal";

import PublicEventsClassModal from "./EventsClassModal";
import AppLoader from "./AppLoader";
// import ChatWindow from "./ChatWindow";
// import ChatList from "./ChatList";
// import SignUpVerifyModal from "./SignUpVerifyModal";
import AlertModal from "./AlertModal";
import PublicAppointments from "./PublicAppointments";
import BookingSteps from "./BookingSteps";
import SignUpSignInHeaderTexts from "./SignUpSignInHeaderTexts";
import AppDatePicker from "./AppDatePicker/AppDatePicker.jsx";
import CampSteps from "./CampSteps/CampSteps.jsx";
import CalendarFms from "./CalendarFms/CalendarFms";
import AppAddToCalendarButton from "./AppAddToCalendarButton";
import AppRedBtn from "./AppRedBtn/AppRedBtn";
import AcceptTC from "./AcceptTC/AcceptTC";

const ClassBookingCalendar = React.lazy(() => import("./ClassBookingCalendar"));

export {
  Header,
  // Sidebar,
  Footer,
  ServiceSelect,
  SelectTrainer,
  SelectTimeSlot,
  ConfirmBooking,
  SignInHeader,
  ToastNotification,
  Calendar,
  DashboardEventsDetailsModal,
  ClassBookingCalendar,
  PublicEventsClassModal,
  AppLoader,
  // ChatWindow,
  // ChatList,
  // SignUpVerifyModal, //! not being used
  AlertModal,
  PublicAppointments,
  BookingSteps,
  SignUpSignInHeaderTexts,
  AppDatePicker,
  CampSteps,
  CalendarFms,
  AppAddToCalendarButton,
  AppRedBtn,
  AcceptTC,
};
