import { Checkbox } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { SIGNUP_FIELDS } from "../../pages/beforeLogin/SignUp/utils";
import { TNC_PRIVACY, TNC_TERMS } from "../../utility/constants";

const AcceptTC = ({ setDispatchItems, state }) => {
  const { tnc18Plus, tncPrivacy, tncTerms } = state || {};
  const onChangeCheckBox =
    (key) =>
    ({ target: { checked } }) => {
      setDispatchItems(key)(checked);
    };

  return (
    <section className="mt-2">
      <section className="d-flex align-items-start">
        <Checkbox
          onChange={onChangeCheckBox(SIGNUP_FIELDS.TNC_TERMS)}
          className="mb-2 font-weight-normal"
          checked={tncTerms}
        />
        <span className="ml-2">
          I have read, understood, and agree to CoachFirst’s{" "}
          <Link to={{ pathname: TNC_TERMS }} target="_blank">
            <b>Terms of Service</b>
          </Link>
        </span>
      </section>
      <section className="d-flex align-items-start">
        <Checkbox
          onChange={onChangeCheckBox(SIGNUP_FIELDS.TNC_PRIVACY)}
          className="mb-2 font-weight-normal"
          checked={tncPrivacy}
        />
        <span className="ml-2">
          I have read, understood, and agree to CoachFirst’s{" "}
          <Link to={{ pathname: TNC_PRIVACY }} target="_blank">
            <b>Privacy Policy</b>
          </Link>
        </span>
      </section>
      <section className="d-flex align-items-start">
        <Checkbox
          onChange={onChangeCheckBox(SIGNUP_FIELDS.TNC_18_PLUS)}
          className="mb-2 font-weight-normal"
          checked={tnc18Plus}
        />
        <span className="ml-2">
          If you are under the age of 18, your parent or legal guardian must
          consent to this agreement and affirm that they accept the agreement on
          your behalf and bear responsibility for your use. If you are accepting
          this agreement on behalf of someone else, you confirm that you have
          the legal authority to bind that person or entity to this agreement.
        </span>
      </section>
    </section>
  );
};

export default AcceptTC;
