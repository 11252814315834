import axios from "axios";
import { BASE_URL_WEB } from "../../../utility/constants";
import { Button } from "antd";

export const checkinConfirmationModalCardStyle = {
  height: "90%",
  boxShadow: "rgba(208, 216, 243, 0.6) 0px 0px 15px 0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const CHECKIN_MODAL_TYPES = Object.freeze({
  CHECKIN_SUCCESS: "CHECKIN_SUCCESS",
  ACCOUNT_FOUND_TRUE: "ACCOUNT_FOUND_TRUE",
  ACCOUNT_FOUND_FALSE: "ACCOUNT_FOUND_FALSE",
  WAIVER: "WAIVER",
  SEND_REGISTRATION_EMAIL: "SEND_REGISTRATION_EMAIL",
});

export const apiFetchCheckinClassDetails = async (key) => {
  const url = `${BASE_URL_WEB}client-self-checkin?key=${key}`;
  let response;
  try {
    response = await axios.get(url);
    console.log("res", response);
    response = { status: response.status, data: response?.data };
  } catch (err) {
    console.log("error", err, err.response);
    response = {
      status: err.response.status,
      data: { message: err.response?.data?.message || "Something went wrong!" },
    };
  }
  return response;
};

export const apiPostCheckin = async (key, data) => {
  const url = `${BASE_URL_WEB}client-self-checkin-update?key=${key}`;
  let response;
  try {
    response = await axios.post(url, data);
    console.log("res", response);
    response = { status: response.status, data: response?.data };
  } catch (err) {
    console.log("error", err, err.response);
    response = {
      status: err.response.status,
      data: { message: err.response?.data?.message || "Something went wrong!" },
    };
  }
  return response;
};

export const apiFetchMemberDetails = async ({ key, email, phone }) => {
  const url = `${BASE_URL_WEB}client-self-checkin-info?key=${key}&phone=${phone}&email=${email}`;
  console.log(url);
  let response;
  try {
    response = await axios.get(url);
    console.log("res", response);
    response = { status: response.status, data: response?.data };
  } catch (err) {
    console.log("error", err, err.response);
    response = {
      status: err.response.status,
      data: { message: err.response?.data?.message || "Something went wrong!" },
    };
  }
  return response;
};

export const apiPostCheckinDropinMembersToClass = async (data) => {
  const url = `${BASE_URL_WEB}client-self-checkin-add-member-to-class`;
  let response;
  try {
    response = await axios.post(url, data);
    console.log("res", response);
    response = { status: response.status, data: response?.data };
  } catch (err) {
    console.log("error", err, err.response);
    response = {
      status: err.response.status,
      data: { message: err.response?.data?.message || "Something went wrong!" },
    };
  }
  return response;
};

export const _renderTableCol = ({ _onPressCheckin }) => [
  {
    title: "NAME",
    key: "name",
    // dataIndex: "name",
    width: "50%",
    render: (_text, record) => (
      <>
        <p className="font-size-16 font-weight-normal">{record?.name}</p>
        <p className="font-size-13 font-weight-normal mt-2 check-in-list-email">
          {record?.email}
        </p>
      </>
    ),
  },
  {
    title: "",
    key: "action",
    render: (_, record) => {
      return (
        <>
          {!record.checkedIn ? (
            <Button
              type="primary"
              className="badge rounded-pill _theme-btn font-size-16 px-4"
              onClick={() => _onPressCheckin(record)}
            >
              Check In
            </Button>
          ) : (
            <span className="text-success text-uppercase font-size-14 font-weight ls-widest">
              Checked in
            </span>
          )}
        </>
      );
    },
  },
];

export const apiPostSignWaiverAndCheckin = async (data) => {
  const url = `${BASE_URL_WEB}client-self-checkin-sign-waiver`;
  let response;
  try {
    response = await axios.post(url, data);
    console.log("res", response);
    response = { status: response.status, data: response?.data };
  } catch (err) {
    console.log("error", err, err.response);
    response = {
      status: err.response.status,
      data: { message: err.response?.data?.message || "Something went wrong!" },
    };
  }
  return response;
};

export const apiPostSendEmail = async (data) => {
  const url = `${BASE_URL_WEB}checkin-send-email`;
  let response;
  try {
    response = await axios.post(url, data);
    console.log("res", response);
    response = { status: response.status, data: response?.data };
  } catch (err) {
    console.log("error", err, err.response);
    response = {
      status: err.response.status,
      data: { message: err.response?.data?.message || "Something went wrong!" },
    };
  }
  return response;
};
