const InfoItem = ({ title, desc, fullWidth, descTextStyles = "" }) => {
  return (
    <div className="my-3">
      <p
        className={`font-size-18 mb-2 align-items-center ${
          fullWidth ? "d-flex justify-content-between" : ""
        }`}
      >
        {title}:{" "}
        {desc && (
          <span className={`ml-1 font-weight-bold ${descTextStyles}`}>
            {fullWidth ? "$ " : ""}
            {desc}
          </span>
        )}
      </p>
    </div>
  );
};

export default InfoItem;
