import React from "react";
import VideoTabData from "./VideoTabData";
import {useSelector} from "react-redux";

function VideosTab() {
  const {details} = useSelector((state) => state.athleteProfile) || {};
  return (
    <>
      <div className="athlete card-row">
        {details?.videos?.length ? (
          details?.videos?.map((video, index) => (
            <VideoTabData key={video.id} video={video} index={index} />
          ))
        ) : (
          <p className="text _text-theme font-size-16 font-italic">
            No videos have been added for this athlete
          </p>
        )}
      </div>
    </>
  );
}

export default VideosTab;
