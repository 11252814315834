import React from "react";
import { Card } from "react-bootstrap";
import PerformanceTabData from "./PerformanceTabData";
import { useSelector } from "react-redux";
import { Collapse } from "antd";
import { formatValue } from "../utils";

function PerformanceTab() {
  const { details } = useSelector((state) => state.athleteProfile) || {};
  const items =
    details?.performance && Object.keys(details?.performance)?.length
      ? Object.keys(details?.performance).map((key, index) => ({
          key: index,
          label: (
            <span className="d-flex justify-content-between">
              <span>{details?.performance[key]?.questionName}</span>
              <span>
                {formatValue(
                  details?.performance[key]?.latestValue?.value,
                  details?.performance[key]?.fieldType
                )}
              </span>
            </span>
          ),
          children: <PerformanceTabData data={details?.performance[key]} />,
        }))
      : [];

  return (
    <>
      {items?.length ? (
        <Card className="performance_tab">
          <Card.Body className="main-container-tab">
            <Collapse accordion items={items} defaultActiveKey={0} />
          </Card.Body>
        </Card>
      ) : (
        <p className="text _text-theme font-size-16 font-italic">
          No data has been added for this athlete
        </p>
      )}
    </>
  );
}

export default PerformanceTab;
