function FrequencyHelper(frequency, type = 1) {
  switch (frequency) {
    case "DAILY":
      switch (type) {
        case 1:
          return "everyday";
        case 2:
          return "next day";
      }
      break;
    case "WEEKLY":
      switch (type) {
        case 1:
          return "every week";
        case 2:
          return "next week";
      }
      break;
    case "MONTHLY":
      switch (type) {
        case 1:
          return "every month";
        case 2:
          return "next month";
      }
      break;
    case "EVERY_TWO_MONTHS":
      switch (type) {
        case 1:
          return "every 2 months";
        case 2:
          return "after 2 months";
      }
      break;
    case "QUARTERLY":
      switch (type) {
        case 1:
          return "every quarter";
        case 2:
          return "in the next quarter";
      }
      break;
    case "EVERY_FOUR_MONTHS":
      switch (type) {
        case 1:
          return "every 4 months";
        case 2:
          return "after 4 months";
      }
      break;
    case "EVERY_SIX_MONTHS":
      switch (type) {
        case 1:
          return "every 6 months";
        case 2:
          return "after 6 months";
      }
      break;
    case "ANNUAL":
      switch (type) {
        case 1:
          return "every year";
        case 2:
          return "next year";
      }
      break;
    case "EVERY_TWO_YEARS":
      switch (type) {
        case 1:
          return "every 2 years";
        case 2:
          return "after 2 years";
      }
      break;
    default:
      return "";
  }
}

export default FrequencyHelper;
