import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  //alert('PublicRoute')
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  console.log("path", path);
  const authDetails = useSelector((state) => state.auth.authDetails);
  const bookingDetails = useSelector((state) => state.booking);
  const redirectPublicUser = useSelector((state) => state.publicUser);
  console.log("restricted", restricted,authDetails,redirectPublicUser);
  const publicRedirectUrl = () => {
   /// alert('publicRedirectUrl')
    if (redirectPublicUser.classId && redirectPublicUser.companyId)
      return "/class-booking";
    else if (
      bookingDetails.service &&
      bookingDetails.trainer &&
      bookingDetails.session_type === "PUBLIC_APPOINTMENT_BOOKING"
    )
      return "/booking";
    else return "/dashboard";
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        authDetails && restricted && path != 'auto-login' ? (
          <Redirect to={publicRedirectUrl()} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
