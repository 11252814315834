import axios from "axios";
import {
  addCompanyData,
  authCompanyData,
  hideCompanyDataLoad,
  showCompanyDataLoad,
} from ".";
import { BASE_URL_WEB, BASE_URL } from "../../utility/constants";
import { AxiosHelperPortal } from "../../utility/helperFunctions";
import {
  REDIRECT_PUBLIC_USER,
  CLEAR_PUBLIC_USER,
  PUBLIC_BRANCH_CHANGE,
} from "../actionTypes";

export const redirectPublicUser = (state) => {
  return {
    type: REDIRECT_PUBLIC_USER,
    payload: state,
  };
};

export const clearPublicUser = () => {
  return {
    type: CLEAR_PUBLIC_USER,
  };
};
export const publicBranchChange = (state) => {
  return {
    type: PUBLIC_BRANCH_CHANGE,
    payload: state,
  };
};

export const handleBranchChange = (state) => {
  const payload = {
    companyId: state.companyId,
    branchId: state.branchId,
  };

  return async (dispatch) => {
    dispatch(showCompanyDataLoad());
    try {
      const response = await AxiosHelperPortal(
        `${BASE_URL_WEB}member-active-branch?`,
        "post",
        payload
      );

      if (response.status === 200) {
        dispatch(addCompanyData(response.data));
        dispatch(hideCompanyDataLoad());
        return;
      }
      dispatch(authCompanyData());
    } catch (error) {
      console.log(error.response);
    }
    dispatch(hideCompanyDataLoad());
  };
};

export const handleConnectToBranch = (state) => {
  return async (dispatch) => {
    dispatch(showCompanyDataLoad());
    try {
      const payload = {
        memberId: state?.username,
        branchId: state?.branchId,
      };

      const response = await axios({
        method: "POST",
        url: BASE_URL + "connect-company",
        data: payload,
      });

      if (response.status === 200) {
        dispatch(authCompanyData());
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(hideCompanyDataLoad());
  };
};
