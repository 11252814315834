import React, { useState, useEffect } from "react";
import Calendar from "@toast-ui/react-calendar";
import Moment from "moment";
import { calendarDateRange, calendarsOptions } from "../../common/config";
import { IMAGE_URL } from "../../utility/constants";
import { Row } from "react-bootstrap";
import {
  calcClassRemainingSpots,
  getCalendarDates,
} from "../../utility/helperFunctions";

const CustomCalendar = ({
  calendarRef,
  onClickSchedule,
  weekView,
  eventsList,
}) => {
  const [dateRange, setDateRange] = useState(calendarDateRange);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // creating the weekly dates array
    let range = getCalendarDates();
    range = getCalendarDates();
    setDateRange({ ...dateRange, range: range });
  }, []);

  useEffect(() => {
    setEventsCalendar();
  }, [dateRange]);

  const getEvents = () => {
    console.log("entered here");

    let allEvents = eventsList;
    // filtering events on the basis of time range
    allEvents = allEvents?.sort(
      (a, b) =>
        new Moment(a.start_branch_time).format("YYYYMMDD") -
        new Moment(b.start_branch_time).format("YYYYMMDD")
    );
    const tempEvents = allEvents?.filter((item) =>
      Moment(item.start_branch_time).isBetween(
        Moment(dateRange.start).subtract(1, "d"),
        Moment(dateRange.end).add(1, "d")
      )
    );

    // combining events of the same day
    const arr = [];
    tempEvents?.forEach((item) => {
      const sameDayEvents = tempEvents?.filter((a) =>
        Moment(a.start_branch_time).isSame(item.start_branch_time, "day")
      );
      arr?.push({
        start_branch_time: Moment(item.start_branch_time).format("MM-DD-YYYY"),
        eventsList: sameDayEvents,
      });
    });

    // removing duplicate events
    const newArray = [];
    const uniqueObject = {};
    let objTitle = "";
    // Loop for the array elements
    for (const i in arr) {
      objTitle = arr[i].start_branch_time;
      uniqueObject[objTitle] = arr[i];
    }
    // Loop to push unique object into array
    for (const i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }
    return newArray;
  };

  const setEventsCalendar = () => {
    const filteredEvents = getEvents();
    // console.log(filteredEvents.length);
    let eventsCalendarList = [];
    // combining the events of filtered events with the calendar array
    if (filteredEvents.length) {
      eventsCalendarList = dateRange.range.map((item) => {
        const temp = {};
        for (const i in filteredEvents) {
          if (filteredEvents[i].start_branch_time === item) {
            temp.start_branch_time = item;
            temp.event = filteredEvents[i];
          } else {
            temp.start_branch_time = item;
          }
        }
        return temp;
      });
    } else {
      // if there are no filtered events then this array is rendered
      eventsCalendarList = dateRange.range.map((item) => {
        const temp = {};
        temp.start_branch_time = item;
        return temp;
      });
    }

    setEvents(eventsCalendarList);
    console.log(eventsCalendarList);
  };

  const moveToNextOrPrev = (value) => {
    const calendarInst = calendarRef.current?.calendarInst;
    if (value === 1) calendarInst.next();
    else if (value === -1) calendarInst.prev();
    // getDateRange();
  };

  const _getTimeTemplate = (schedule) => {
    const html = [];
    html.push(
      Moment(schedule.start._date).format("h:mm") + " " + schedule.title
    );
    return html.join("");
  };

  const templates = {
    time: function (schedule) {
      return _getTimeTemplate(schedule, false);
    },
    timegridCurrentTime: function () {
      var templates = [];
      templates.push(Moment().format("h:mm a"));
      return templates.join("");
    },
  };

  return weekView ? (
    <Row>
      <div className="_fB-lft col-lg-12 col-xl-2"></div>

      <div className="_fB-mid col-lg-8 col-xl-6">
        <div className="_calenderSlider-div ">
          {/* <!-- Item : Begin ---> */}
          <div className="item">
            <div className="_dateRange" style={{ fontSize: "16px" }}>
              <p style={{ display: "flex" }}>
                <span>
                  <button
                    className="_slider-arrow"
                    onClick={() => {
                      if (!weekView) {
                        moveToNextOrPrev(-1);
                      } else {
                        const range = getCalendarDates(
                          Moment(dateRange.start).subtract(6, "d"),
                          dateRange.start
                        );
                        setDateRange({
                          start: Moment(dateRange.start).subtract(6, "d"),
                          end: dateRange.start,
                          range: range,
                        });
                      }
                    }}
                  >
                    <i className="bx bx-caret-left"></i>
                  </button>
                </span>
                {!weekView ? (
                  // <span>{nonWeekDate}</span>
                  <span>some</span>
                ) : (
                  <span>{`${Moment(dateRange.start).format(
                    "MMMM Do, YYYY"
                  )} - ${Moment(dateRange.end).format("MMMM Do, YYYY")}`}</span>
                )}
                <span>
                  <button
                    className="_slider-arrow"
                    onClick={() => {
                      if (!weekView) {
                        moveToNextOrPrev(1);
                      } else {
                        const range = getCalendarDates(
                          dateRange.end,
                          Moment(dateRange.end).add(6, "d")
                        );
                        setDateRange({
                          start: dateRange.end,
                          end: Moment(dateRange.end).add(6, "d"),
                          range: range,
                        });
                      }
                    }}
                  >
                    <i className="bx bx-caret-right"></i>
                  </button>
                </span>
              </p>
            </div>
            {/* <!-- Item : End ---> */}
          </div>
        </div>
      </div>
      <div className="_bookingTable-outter mt-3">
        <div className="table-responsive">
          <table className="table _dateBookingTable">
            {events.map((item, index) => {
              const branchStartTime = item.start_branch_time;
              const fixDatebranchStartTime = branchStartTime.replace(/-/g, "/");
              const formattedBranchStartTime = Moment(
                fixDatebranchStartTime
              ).format("Do MMMM YYYY");
              return (
                <tr
                  key={index}
                  style={{
                    backgroundColor:
                      item?.isBooked && "rgb(232 62 140 / 5%) !important",
                  }}
                >
                  <td className="left_td">
                    <span className="_boldTxt _lgTxt">
                      {Moment(branchStartTime, "MM-DD-YYYY").format("DD")}
                    </span>{" "}
                    <span className="_smTxt">
                      {" "}
                      {Moment(branchStartTime, "MM-DD-YYYY").format("ddd")}
                    </span>
                  </td>
                  <td className="booked_details">
                    <table className="table _dateBookingTable-inner booked_table">
                      <tr>
                        <td>
                          <strong>{formattedBranchStartTime}</strong>
                        </td>
                        {/* <td>
                    <button className="btn _signBtn">Book</button>
                  </td> */}
                      </tr>
                      {item.event?.eventsList.map((data, index) => (
                        <tr key={index}>
                          <td colSpan="2">
                            <div
                              className="booked-wrapper border position-relative"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                              }}
                            >
                              {(data?.typename === "appointment" ||
                                data?.isBooked) && (
                                <div className="ribbon ribbon-top-right">
                                  <span>
                                    {data?.typename === "appointment"
                                      ? "1:1 session"
                                      : data?.isBooked && "Booked!"}
                                  </span>
                                </div>
                              )}
                              <div
                                className="booked-info"
                                style={{
                                  backgroundColor: "none",
                                  boxShadow: "none",
                                  border: "none",
                                }}
                              >
                                {data?.imageUrl && (
                                  <div className="service_img pb-2 d-block d-sm-none d-md-none">
                                    <img
                                      src={IMAGE_URL + data?.imageUrl}
                                      alt=""
                                      width="200"
                                      height="120"
                                    />
                                  </div>
                                )}

                                <h4>
                                  {data.serviceName}{" "}
                                  {data.virtualSession && (
                                    <i className="fas fa-video"></i>
                                  )}
                                </h4>

                                <span className="info _smTxt date mt-2">
                                  <i className="bx bx-time-five"></i>
                                  {`${Moment(data.start_branch_time).format(
                                    "hh.mm A"
                                  )} - ${Moment(data.end_branch_time).format(
                                    "hh.mm A"
                                  )}`}
                                </span>

                                <span className="info _smTxt">
                                  <i className="bx bx-user"></i>

                                  {data.typename === "appointment" ? (
                                    <span className="">{data.trainerName}</span>
                                  ) : (
                                    data.selectedTrainers.map(
                                      (trainer, index) => (
                                        <span className="" key={trainer.id}>
                                          <i className="bx bx-user"></i>
                                          {trainer.trainerName}
                                          {data?.selectedTrainers?.length -
                                            1 !==
                                            index && ", "}
                                        </span>
                                      )
                                    )
                                  )}
                                </span>
                                {/* {JSON.stringify(data)} */}

                                {data.typename !== "appointment" && (
                                  <span className="info _smTxt">
                                    <i className="bx bx-bookmark"></i>
                                    Remaining Spots:{" "}
                                    {calcClassRemainingSpots(data)}
                                  </span>
                                )}
                                {data.description !== "" && (
                                  <p
                                    className="desc-normal limit-2 mb-2"
                                    dangerouslySetInnerHTML={{
                                      __html: data.description,
                                    }}
                                  />
                                )}
                                {/* {data?.familyMembers?.length > 0 && (
                                  <span className="info _smTxt">
                                    <i
                                      className="far fa-calendar-check"
                                      style={{ color: "green" }}
                                    ></i>
                                    {data?.familyMembers?.map(
                                      (item, index) =>
                                        `${item?.firstName}${
                                          data?.familyMembers?.length - 1 !==
                                          index
                                            ? ", "
                                            : ""
                                        }`
                                    )}
                                  </span>
                                )} */}
                                {/* <br />
                                <button
                                  // onClick={() => {
                                  //   // if (!companyData.redirected) {
                                  //   console.log("user data is", data);
                                  //   if (data?.typename !== "appointment") {
                                  //     setClassDetails(data);
                                  //     setModalShow(!modalShow);
                                  //   } else {
                                  //     setEventDetails(data);
                                  //     setEventModalShow(!eventModalShow);
                                  //   }
                                  //   // }
                                  // }}
                                  className="btn view-det mr-1"
                                >
                                  View Details
                                </button> */}

                                {/* <div className="badge rounded-pill bg-success ml-1">

                                {data.typename === "appointment"
                                  ? "1:1 session"
                                  : data.isBooked && "Booked!"}
                              </div> */}
                              </div>
                              <div className="booked_img d-none d-xxl-block">
                                {data?.imageUrl && (
                                  <img
                                    src={IMAGE_URL + data?.imageUrl}
                                    alt=""
                                    width="300"
                                    height="180"
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </Row>
  ) : (
    <Calendar
      calendars={calendarsOptions}
      disableDblClick={true}
      disableClick={false}
      isReadOnly={true}
      scheduleView={true}
      taskView={false}
      template={templates}
      usageStatistics={false}
      timezone={[
        {
          timezoneOffset: new Date().getTimezoneOffset(),
        },
      ]}
      useDetailPopup={false}
      onClickSchedule={onClickSchedule}
      ref={calendarRef}
    />
  );
};

export default CustomCalendar;
