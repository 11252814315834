import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const AlertModal = ({
  title,
  isShow,
  type,
  _handleConfirmBtn,
  _handleCancelBtn,
  message,
  showCancel,
}) => {
  return (
    <SweetAlert
      title={title}
      showCancel={showCancel}
      show={isShow}
      type={type}
      onConfirm={_handleConfirmBtn}
      onCancel={_handleCancelBtn}
    >
      {message}
    </SweetAlert>
  );
};

export default AlertModal;
