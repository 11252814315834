import React, {useState, useEffect} from "react";
import {calendar} from "../../assets/imageDir";
import Moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {
  changeTrainingTimePage,
  changeServicePage,
  clearTimeSlots,
  setSessionTrainingDate,
  setSessionTrainingTime,
  setSelectedFamilyMemberSession,
} from "../../redux/actions/index";
import {BASE_URL_WEB, IMAGE_URL} from "../../utility/constants";
import {
  AxiosHelperPortal,
  dateFormatter,
  getInitials,
  tConvert,
} from "../../utility/helperFunctions";
import {AppLoader} from "..";
import BookingSteps from "../BookingSteps";
import FamilyMemberListSession from "../FamilyMemberListSession/FamilyMemberListSession";

const BookingPage3 = () => {
  const [loading, setLoading] = useState(true);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [alertShow, setAlertShow] = useState({
    show: false,
    msg: "",
  });
  const [isShowTimeSlot, setShowTimeSlot] = useState({
    show: true,
    message: "",
  });
  const dispatch = useDispatch();
  const bookingDetails = useSelector((state) => state.booking);
  const trainingTime = bookingDetails?.trainingTime;
  const authDetails = useSelector((state) => state.auth);

  const selectedTrainer =
    authDetails.companyData?.trainers?.items[bookingDetails?.trainer?.id];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //todo rerender issue
  useEffect(() => {
    _handleDateChange(trainingTime?.date);
  }, []);

  const fetchTimeSlots = async () => {
    // const selectedTrainer = bookingDetails.trainer;

    const url = `${BASE_URL_WEB}trainer-availability?branchTrainerTableId=${
      selectedTrainer?.id
    }&date=${trainingTime?.date}&duration=${
      bookingDetails.service?.duration
    }&extraTime=${0}`;
    // console.log("fetchcalendar : ", date);
    console.log(url);
    // get trainer availabilies
    AxiosHelperPortal(url, "get", authDetails.authDetails.username, "member")
      .then((response) => {
        const data = response.data;
        console.log(response);
        setAvailableTimes(data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const _dateAndTimeValidation = () => {
    dispatch(changeTrainingTimePage());
  };

  const _handleDateChange = (value) => {
    const presentDate = new Moment();
    const trainingDate = Moment(value);
    let timeLimit = null;
    if (authDetails?.companyData?.booking_max_days)
      timeLimit = Moment().add(
        parseInt(authDetails?.companyData?.booking_max_days ?? 30),
        "days"
      );
    else timeLimit = Moment().add(30, "days");
    if (trainingDate.isBefore(presentDate, "date")) {
      setShowTimeSlot({
        show: false,
        message: `Please add a future date or time.`,
      });
      return;
    }
    if (trainingDate.isAfter(timeLimit)) {
      setShowTimeSlot({
        show: false,
        message: `Sorry! Please select date from now to ${
          authDetails?.companyData?.booking_max_days
            ? authDetails?.companyData?.booking_max_days
            : 30
        } days.`,
      });
      return;
    }
    setShowTimeSlot({
      show: true,
      message: "",
    });

    setAlertShow({...alertShow, show: false});
    fetchTimeSlots(value);
  };

  const _handleTimeChange = (e) => {
    dispatch(setSessionTrainingTime(e.target.value));
  };

  const _handleFmChange = (fmId) => {
    dispatch(setSelectedFamilyMemberSession(fmId));
  };
  console.log("isShowTimeSlot", isShowTimeSlot, availableTimes);
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container">
            <div className="container">
              <div className="_cardTop-hdr mb-4 text-center page-title">
                <h2 className="_text-dark">Select Date & Time</h2>
              </div>

              <BookingSteps step={3} />
              <div className="_sessionBookingPage mt-2">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="booking_page">
                      <div className="_bookingTable-outter _list-outter">
                        <>
                          <div className="_clientProDiv">
                            <span className="_proPic">
                              {selectedTrainer?.imageUrl ? (
                                <img
                                  src={IMAGE_URL + selectedTrainer?.imageUrl}
                                  alt=""
                                />
                              ) : (
                                <span className="avName">
                                  <span className="proName">
                                    {getInitials(
                                      selectedTrainer?.firstName,
                                      selectedTrainer?.lastName
                                    )}
                                  </span>
                                </span>
                              )}
                            </span>
                            <div className="_clCont">
                              <p className="_name _title-2">
                                {selectedTrainer?.trainerName}
                              </p>
                              <p className="_info">{selectedTrainer?.email}</p>
                            </div>
                          </div>
                          <div className="_bookingDetails">
                            <p>
                              <span className="text-dark">
                                {bookingDetails?.service?.serviceName}
                              </span>
                            </p>
                            <div className="row mb-3">
                              <p className="col-md-12 col-form-label pb-0 pt-0 mb-0">
                                <strong className="text-dark">
                                  Select Date :{" "}
                                </strong>
                              </p>
                              <div className="col-md-4">
                                <input
                                  className="form-control"
                                  type="date"
                                  defaultValue={dateFormatter(
                                    trainingTime?.date ?? Moment(),
                                    1
                                  )}
                                  id="example-date-input"
                                  onChange={(e) => {
                                    // _handleDateChange(e.target.value);
                                    dispatch(
                                      setSessionTrainingDate(e.target.value)
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            {isShowTimeSlot.show ? (
                              <>
                                <p>
                                  <strong className="text-dark">
                                    Select Time :{" "}
                                  </strong>
                                </p>
                                <div className="_timeListDiv">
                                  <ul className="_timeList">
                                    {loading ? (
                                      <AppLoader height={50} width={50} />
                                    ) : (
                                      <>
                                        {availableTimes?.map((item, index) => (
                                          <li key={index}>
                                            <label className="_t-label">
                                              <input
                                                type="radio"
                                                name="time"
                                                value={item}
                                                checked={
                                                  item === trainingTime?.time
                                                }
                                                onChange={_handleTimeChange}
                                              />
                                              <span className="_timeSpan">
                                                {tConvert(item)}
                                              </span>
                                            </label>
                                          </li>
                                        ))}
                                        {availableTimes?.length === 0 && (
                                          <span style={{color: "red"}}>
                                            No times available on this date.
                                          </span>
                                        )}
                                      </>
                                    )}

                                    {/* <li
                                onClick={() => {
                                  setShowTimeField(!showTimeField);
                                  setTime({ ...time, custom: true });
                                }}
                              >
                                <label className="_t-label">
                                  <span
                                    className={
                                      showTimeField || time.custom
                                        ? "_timeSpan _selected"
                                        : "_timeSpan"
                                    }
                                    id="cT-btn"
                                  >
                                    Custom Time
                                  </span>
                                </label>
                              </li> */}
                                  </ul>
                                  {/* <div
                                className={
                                  showTimeField ? "_ctimeDiv show" : "_ctimeDiv"
                                }
                              >
                                <input
                                  type="time"
                                  className="form-control"
                                  onChange={(e) => {
                                    setTime({
                                      time: e.target.value,
                                    });
                                    console.log(e.target.value);
                                    // _handleTimeChange(e.target.value);
                                  }}
                                />
                              </div> */}
                                  <div className="_finalDate">
                                    <span className="_icon">
                                      <img src={calendar} alt="" />
                                    </span>
                                    <p>
                                      {dateFormatter(
                                        trainingTime?.date ?? Moment(),
                                        2
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <span style={{color: "red"}}>
                                {" "}
                                {isShowTimeSlot.message}
                              </span>
                            )}
                          </div>
                        </>
                        <FamilyMemberListSession
                          _handleFmChange={_handleFmChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="_btnGrp text-center py-4">
            <button
              className="btn btn-outline-danger px-5"
              onClick={() => {
                dispatch(changeServicePage(bookingDetails.service));
                dispatch(clearTimeSlots());
              }}
            >
              Back
            </button>
            <button
              className="btn btn-primary px-5"
              onClick={_dateAndTimeValidation}
              disabled={
                !isShowTimeSlot.show ||
                !trainingTime?.date ||
                !trainingTime?.time
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingPage3;
