import { useDispatch, useSelector } from "react-redux";
import {
  addClassBookingDetails,
  addSelectedPackage,
  setSelectedFamilyMembers,
} from "../../../redux/actions";
import { useState } from "react";
import { Modal, Space, Tag, Tooltip } from "antd";
import { BASE_URL_WEB } from "../../../utility/constants";
import { AxiosHelperPortal, showToast } from "../../../utility/helperFunctions";
import { authCompanyData } from "../../../redux/actions";
import { Link, useHistory } from "react-router-dom";
import "../ConfirmBooking.scss";

const FamilyMembers = ({ isCamp, isBookedIsCampNoBooking,isClassFull = false}) => {
  console.log("isCamp", isCamp)
  const dispatch = useDispatch();
  const bookingDetails = useSelector((state) => state.booking);
  const authDetails = useSelector((state) => state.auth);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRegistrant, setSelectedRegistrant] = useState([]);
  const [error, setError] = useState(null);
  const history = useHistory();

  console.log("bookingDetails", bookingDetails);
  // let classBookingFamilyMembers = {
  //   //todo add primary member?
  //   bookedFm: [],
  //   nonBookedFm: [],
  // };
  const isParentObj = {
    isParent: true,
    firstName: authDetails?.companyData?.member?.firstName,
    lastName: authDetails?.companyData?.member?.lastName,
    uuid: "_parent",
  };

  const isParentBooked = bookingDetails?.classDetails?.familyMembers?.find(
    (e) => e?.isParent
  );
  const isPrivateClass = bookingDetails?.classDetails?.access === "private";
  console.log("isParentBooked", isParentBooked);
  // if (isParentBooked) {
  //   classBookingFamilyMembers.bookedFm.push(isParentObj);
  // } else {
  //   classBookingFamilyMembers.nonBookedFm.push(isParentObj);
  // }

  // authDetails?.companyData?.familyMembers?.forEach((fm) => {
  //   if (
  //     bookingDetails?.classDetails?.familyMembers?.find(
  //       (e) => e?.id === fm?.uuid
  //     )
  //   ) {
  //     classBookingFamilyMembers.bookedFm.push(fm);
  //   } else {
  //     classBookingFamilyMembers.nonBookedFm.push(fm);
  //   }
  // });
  //console.log("classBookingFamilyMembers", classBookingFamilyMembers);
  // const [bookedFm, setBookedFm] = useState(
  //   classBookingFamilyMembers.bookedFm || []
  // );
  // const [nonBookedFm, setNonBookedFm] = useState(
  //   classBookingFamilyMembers.nonBookedFm || []
  // );
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (selectedRegistrant?.length === 0) {
      setError("Please select at least one registrant");
      return;
    }

    const memberId = authDetails?.companyData?.member?.id;
    let payload = {
      cancelAllAppointments: null,
      cancelType: "session_credit",
      cancelledReason: "Cancelled by client",
      groupClassId: bookingDetails?.classDetails?.uuid,
      partialRefundAmount: null,
      paymentMethod: "pay_now_cash",
      refundType: null,
      uuid: bookingDetails?.classDetails?.bookingId,
      memberId: memberId,
      isGroupClass: "yes",
      from: "client_portal",
      fmIds: selectedRegistrant,
    };
    console.log("payload", payload);
    const url = `${BASE_URL_WEB}appointment-cancel?`;
    console.log("url", url, payload);
    try {
      const response = await AxiosHelperPortal(url, "post", payload);
      console.log("response", response);
      setError(null)
      dispatch(authCompanyData());
      // dispatch(addClassBookingDetails(response.class_details));
      // dispatch(addSelectedPackage(null));
      // move to classes page
      history.push({
        pathname: "/class-booking",
      });
      showToast.success("Your session is cancelled successfully.");
    } catch (error) {
      console.log("err", error);
      showToast.error(error.response?.data?.message);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const _handleOnSelectFamilyMem = (e, item) => {
    if (e.target.checked) {
      if(isClassFull){
        showToast.error(bookingDetails?.classDetails?.isCamp ? "This camp is full. Please select another service to continue booking." : "This class is full. Please select another service to continue booking.");
        return;
      } else {
        dispatch(
          setSelectedFamilyMembers([
            ...(bookingDetails?.selectedFamilyMembers || []),
            item,
          ])
        );
        return;
      }
    }
    const removed = bookingDetails?.selectedFamilyMembers?.filter(
      (ele) => ele.uuid !== item.uuid
    );
    dispatch(setSelectedFamilyMembers(removed));
  };

  const renderFamilyMembers = (family) => {
    return (
      <div key={family.uuid} className="col">
        <div className="card mb-4 rounded-3 shadow-sm bg-light">
          <div className="card-body">
            <ul className="list-unstyled my-0">
              <li className="p-1 border-bottom">
                <strong>Full Name : </strong>
                {family?.firstName} {family?.lastName}
              </li>
              {family?.isParent && (
                <span className="text">(Primary Member)</span>
              )}
              {/* <li className="p-1">
                          <strong>Age : </strong>
                          {family?.age} years
                        </li> */}
            </ul>
            <div className="form-check pl-0 position-absolute top-0 end-0 pt-2">
              <input
                className="form-check-input"
                type="checkbox"
                id={`check2-${family.uuid}`}
                checked={
                  bookingDetails?.selectedFamilyMembers?.filter(
                    (child) => child?.uuid === family?.uuid
                  ).length
                }
                onChange={(e) => _handleOnSelectFamilyMem(e, family)}
                // defaultChecked={false}
              />
              <label className="form-check-label" htmlFor="check2">
                &nbsp;
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="family_member">
        {bookingDetails?.classDetails?.familyMembers?.length > 0 && (
          <>
            <div className="d-flex justify-content-between align-items-center text-dark bg-light text-left px-3 pl-3 my-3 text-dark fw-normal py-2">
              <p>Current registrants</p>
              {isCamp && (
                <>
                  <Tooltip
                    placement="top"
                    title="Please contact the coach to cancel this registration"
                  >
                    <button className="btn btn-primary">Cancel</button>
                  </Tooltip>
                  {/* //! don't remove, roll-backed refund, need later */}
                  {/* <Popconfirm
                    placement="topRight"
                    title={`Refund`}
                    description={
                      <span>
                        All the registrants for all the days of the camps will
                        be cancelled. Are you sure you want to continue?
                        <br />
                        If you want to cancel just one registrant, please
                        contact your coach.
                      </span>
                    }
                    icon={<BiHelpCircle color={PRIMARY_COLOR} />}
                    onConfirm={onRefund}
                  >
                    <button className="btn btn-primary">Refund?</button>
                  </Popconfirm> */}
                </>
              )}
              {!isCamp && (
                <button className="btn btn-primary" onClick={showModal}>
                  Cancel
                </button>
              )}
            </div>
            {bookingDetails?.classDetails?.familyMembers?.map((family) => {
              return (
                <Tag
                  key={family.uuid}
                  className="custom-chip"
                  style={{
                    borderRadius: "15px",
                    padding: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Space className="px-1">
                    <span className="text">
                      {family?.isParent &&
                        (authDetails?.companyData?.member?.firstName ?? "") +
                          " " +
                          (authDetails?.companyData?.member?.lastName ?? "")}
                      {!family?.isParent &&
                        (family?.firstName ?? "") +
                          " " +
                          (family?.lastName ?? "")}
                    </span>
                  </Space>
                </Tag>
              );
            })}
          </>
        )}

        {/* {isBookedIsCampNoBooking && (
          <p className="text-center pt-2 text-danger text font-size-16">
            {isBookedIsCampNoBooking}
          </p>
        )} */}
        {!isPrivateClass && (
          <>
            <div className="d-flex justify-content-between align-items-center text-dark bg-light text-left px-3 pl-3 my-3 text-dark fw-normal py-2">
              <p>Select additional registrants</p>
              {/* <p role="button" className="_boldTxt mt-2">

              </p> */}
              <Link
                to={`/family-members/${bookingDetails?.classDetails?.bookingId}`}
              >
                <button className="btn btn-primary">
                  + Add New Family Member
                </button>
              </Link>
            </div>
            <div className="row row-cols-1 row-cols-md-4 mb-3">
              {
                // if parent is not booked then show parent
                !bookingDetails?.classDetails?.familyMembers?.find(
                  (e) => e?.isParent
                ) && renderFamilyMembers(isParentObj)
              }
              {authDetails?.companyData?.familyMembers
                ?.filter((family) => {
                  return !bookingDetails?.classDetails?.familyMembers?.find(
                    (e) => e?.id === family?.uuid
                  );
                })
                ?.map((family) => {
                  return renderFamilyMembers(family);
                })}
            </div>
          </>
        )}
      </div>
      <Modal
        title="Cancel Registration"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label>Please choose the registrants to cancel. </label>
        <div className="row">
          <div className="col-md-6">
            {bookingDetails?.classDetails?.familyMembers?.map((r) =>
              r.isParent ? (
                <div key="_parent" className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="parentCheckbox"
                    value="_parent"
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedRegistrant((prev) =>
                        prev.includes(value)
                          ? prev.filter((registrant) => registrant !== value)
                          : [...prev, value]
                      );
                      setError(null)
                    }}
                  />
                  <label className="form-check-label" htmlFor="parentCheckbox">
                    {(authDetails?.companyData?.member?.firstName
                      ? authDetails?.companyData?.member?.firstName
                      : "") +
                      " " +
                      (authDetails?.companyData?.member?.lastName
                        ? authDetails?.companyData?.member?.lastName
                        : "")}
                  </label>
                </div>
              ) : (
                <div key={r.id} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`checkbox_${r.id}`}
                    value={r.id}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedRegistrant((prev) =>
                        prev.includes(value)
                          ? prev.filter((registrant) => registrant !== value)
                          : [...prev, value]
                      );
                      setError(null)
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox_${r.id}`}
                  >
                    {(r.firstName ? r.firstName : "") +
                      " " +
                      (r.lastName ? r.lastName : "")}
                  </label>
                </div>
              )
            )}
          </div>
        </div>
        {error && <div className="text-danger">{error}</div>}
      </Modal>
    </>
  );
};

export default FamilyMembers;
