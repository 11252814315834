import { BASE_URL_WEB } from "../../utility/constants";
import { AxiosHelperPortal } from "../../utility/helperFunctions";

export const apiPostAcceptTnc = async (data) => {
  const url = `${BASE_URL_WEB}update-toc?`;
  let response;
  try {
    response = await AxiosHelperPortal(url, "post", data);
    console.log("res", response);
    response = { status: response.status, data: response?.data };
  } catch (err) {
    console.log("error", err, err.response);
    response = {
      status: err.response.status,
      data: { message: err.response?.data?.message || "Something went wrong!" },
    };
  }
  return response;
};
