import React, {useState, useEffect} from "react";
import {Modal} from "react-bootstrap";
import Moment from "moment";
import {useHistory} from "react-router-dom";
import {
  addSelectedPackage,
  clearCompanyData,
  redirectPublicUser,
  addClassBookingDetails,
} from "../../redux/actions/index";
import {ToastNotification} from "../index";
import {IMAGE_URL, BASE_URL_WEB} from "../../utility/constants";
import {useSelector, useDispatch} from "react-redux";
import {calendar} from "../../assets/imageDir";
import {
  AxiosHelperPortal,
  openInNewTab,
  calcClassRemainingSpots,
} from "../../utility/helperFunctions";
import {BOOKING_TYPES} from "../../common/config";

const EventsClassModal = ({show, onHide, details}) => {
  const history = useHistory();
  const authDetails = useSelector((state) => state.auth);
  const [memberService, setMemberService] = useState({});
  const [groupClassDetail, setGroupClassDetail] = useState({});
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [membership_pack, setMembershipPack] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [isClassFull, setIsClassFull] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    let temp = {};

    if (authDetails?.companyData?.member_service?.length > 0) {
      temp = authDetails?.companyData?.member_service.filter(
        (e) => e.serviceId === details?.serviceId
      );
      const groupClassDetails =
        authDetails?.companyData?.services?.group_class?.filter(
          (item) => item?.uuid === details?.serviceId
        );
      setGroupClassDetail(groupClassDetails);
      setMemberService(temp[0]);
      console.group("events class modal");
      console.log(details);
      // console.log(temp[0]);
      console.log(groupClassDetails[0]?.campDaysBookTogether);
      console.groupEnd("events class modal");
      if (calcClassRemainingSpots(details) === "Class Full") {
        setIsClassFull(true);
      } else {
        setIsClassFull(false);
      }
    }
  }, [details]);
  console.log("isClassFull", isClassFull);
  useEffect(() => {
    const member_services = authDetails?.companyData?.member_service;
    let new_member_service = null;
    if (member_services?.length != null && member_services.length > 0) {
      new_member_service = member_services.filter(
        (e) => e.typename === "membership_plan_subscribers"
      );
    }
    if (new_member_service !== null && details?.serviceId != null) {
      getRemainingSpotsAjax();
    } else {
      getRemainingSpots();
    }
  }, [details, authDetails?.companyData]);
  console.log("details details", details, groupClassDetail);
  const getRemainingSpotsAjax = () => {
    let url = BASE_URL_WEB + "client-class-member-service?";
    url += "&serviceId=";
    url += details?.serviceId;
    console.log("member-branch-trainer url", url);
    setLoading(true);
    AxiosHelperPortal(url, "get")
      .then((response) => {
        console.log("fetch remaining data", response);
        const data = response.data;
        const membership_pack = data?.membership_pack;
        const unlimited = data?.unlimited;
        console.log(data.length);
        if (data.length === 0) {
          setRemainingCredits(0);
          setMembershipPack(membership_pack);
        } else {
          setRemainingCredits(data.ptRemaining);
          setMembershipPack(membership_pack);
          setIsUnlimited(unlimited);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        setLoading(false);
      });
  };

  const getRemainingSpots = () => {
    let remaining = 0;
    const member_services = authDetails?.companyData?.member_service;
    const multi_class_pack =
      authDetails?.companyData?.services?.multi_class_pack;
    if (member_services?.length != null && member_services.length > 0) {
      let new_member_service = member_services.filter(
        (e) =>
          (e.typename === "member_multi_class_pack" &&
            e?.ptRemaining != null &&
            e.ptRemaining > 0 &&
            multi_class_pack.length != null &&
            multi_class_pack.length > 0 &&
            multi_class_pack.find(
              (m) =>
                (m.uuid === e.multiClassPackId && m?.isAllClassPack === true) ||
                (m?.selectedClasses?.length != null &&
                  m?.selectedClasses?.length > 0 &&
                  m?.selectedClasses.includes(details?.serviceId))
            )) ||
          (e.typename === "member_service" &&
            e?.ptRemaining != null &&
            e.ptRemaining > 0 &&
            e.serviceId === details?.serviceId)
      );
      if (
        new_member_service?.length != null &&
        new_member_service?.length > 0
      ) {
        remaining = new_member_service.reduce((total, val) => {
          if (val?.ptRemaining != null) {
            return total + val.ptRemaining;
          }
          return total;
        }, 0);
      }
    }
    console.log("==============================", remaining);
    setRemainingCredits(remaining);
  };

  // const checkIsAllFamilyBooked = () => {
  //   const familyMembers = authDetails?.companyData?.familyMembers;
  //   console.log(location.state?.familyMembers);

  //   const bookedFamilyMemberslength =
  //     familyMembers?.filter(
  //       (item) => !details?.familyMembers?.some((e) => e.uuid === item.uuid)
  //     )?.length || 0;
  //   console.log(bookedFamilyMemberslength);
  //   return bookedFamilyMemberslength === 0;
  // };

  const onBook = async () => {
    dispatch(addSelectedPackage(null));
    details.isClassFull = isClassFull;
    dispatch(addClassBookingDetails(details));
    //todo remove appoint trainer
    history.push({
      pathname: "/confirm-booking",
    });
  };

  const onBookPublicMoveToLoginPage = () => {
    dispatch(addSelectedPackage(null));
    dispatch(clearCompanyData());
    dispatch(
      redirectPublicUser({
        companyId: authDetails?.companyData?.id,
        companyName: authDetails?.companyData?.name,
        classId: details?.uuid,
        branchDetails: authDetails?.companyData?.branches?.items,
        imageUrl: authDetails?.companyData?.imageUrl,
        showKidsAfterSignup: authDetails?.companyData?.showKidsAfterSignup,
        eventDate: details?.start_branch_time,
        haveKids: details?.haveKids,
      })
    );
    history.push("/login");
  };

  const showContinueBtnText = () => {
    let txt = "Continue Booking";

    const isBookedIsCampNoBooking = details?.isBooked && details?.isCamp;
    if (isBookedIsCampNoBooking) {
      txt = "View Details";
    } else if (
      details?.isBooked &&
      details.typename !== BOOKING_TYPES.APPOINTMENT
    ) {
      txt = "Update Registration";
    }
    return txt;
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="class-info-modal"
      >
        <div className="modal-content-inner">
          {!isClassFull ? (
            <>
              {details?.status !== "cancelled" && (
                <div className="btn-div text-center mt-3">
                  {authDetails.authDetails ? (
                    <button
                      className="btn _btnRound"
                      // disabled={isContinueBookingDisabled({
                      //   selectedClass: details,
                      //   loading,
                      // })}
                      onClick={onBook}
                    >
                      {showContinueBtnText()}
                    </button>
                  ) : (
                    <button
                      className={"btn _btnRound"}
                      onClick={onBookPublicMoveToLoginPage}
                      style={{backgroundColor: "#c82638", color: "white"}}
                    >
                      Continue Booking
                    </button>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              
              {details.haveKids && details.haveKids === true ? (
                <>
                 <button className="btn _btnRound" onClick={onBook}>
                      Update Registration
                  </button>
                </>
              ) : (
                <>
                  <button className="btn _btnRound" disabled={true}>
                    {details.isCamp ? 'Camp Full' : 'Class Full'}
                  </button>
                </>
              )}
            </>
          )}
          <div className="modal-header">
            <h5 className="modal-title">{`${
              details?.isCamp ? "Camp Details" : "Class Details"
            }`}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            ></button>
          </div>
          <div className="modal-body">
          {isClassFull && details.haveKids && details.haveKids === true && (
                <div
                style={{ backgroundColor: '#f8d7da', padding: '5px', borderRadius: '4px' ,fontFamily: 'Arial, sans-serif'}}
              >
                <h6 className="text-danger">{details.isCamp ? 'Camp Full' : 'Class Full'}</h6>
              </div>
              
              )}
            <div className="classInfoBlock">
              <figure className="classInfo-img">
                {details?.imageUrl && (
                  <img
                    src={IMAGE_URL + details?.imageUrl}
                    alt=""
                    className="img-fluid"
                  />
                )}
              </figure>

              <div>
                <p
                  className="service text-dark _name _title-3"
                  style={{fontWeight: "500"}}
                >
                  <span className="icon-holder-txt">
                    {details?.virtualSession && (
                      <i className="fas fa-video"></i>
                    )}
                    {details?.isCamp && details?.commonT
                      ? details?.commonT
                      : details?.serviceName}
                    , {details?.branchName}
                  </span>
                </p>

                <pre className="desc-normal">
                  {details?.isCamp && details?.commonDes
                    ? details?.commonDes
                    : details?.description}
                </pre>

                {details?.zoomLink && details?.isBooked && (
                  <div className="zoomLink">
                    <p className="_name _title-3 mr-2">Zoom Link:</p>
                    <span
                      className="iconRound"
                      onClick={() => openInNewTab(details?.zoomLink)}
                      onKeyPress={null}
                      role="link"
                      tabIndex={-1}
                    >
                      <i className="bx bx-video"></i>
                    </span>
                  </div>
                )}
              </div>

              {!details?.onlineBooking &&
                typeof details?.access !== "undefined" &&
                details?.access === "private" && (
                  <div className="text-center m-3">
                    <span className="text-danger font-weight-bold">
                      Sorry ! This class is not available for online booking.
                    </span>
                  </div>
                )}
              {details?.status === "cancelled" && (
                <div className="text-center m-3">
                  <span className="text-danger font-weight-bold">
                    Sorry! This class is cancelled.
                  </span>
                </div>
              )}

              <div className="date-time-div">
                <div className="date-div">
                  <span className="_icon">
                    <img src={calendar} alt="" />
                  </span>
                  {Moment(details?.start_branch_time).format(
                    "dddd, MMMM Do YYYY"
                  )}
                </div>
                <div className="time-div">
                  <span className="_icon">
                    <i className="far fa-clock"></i>
                  </span>
                  {Moment(details?.start_branch_time).format("hh:mm A")}
                </div>
              </div>
            </div>

            <div className="cinfo-div-outter mb-5 mt-3">
              <div className="cinfo-div mb-2">
                <span className="cinfo-title">
                  <i className="bx bx-user"></i> Coaches
                </span>
                <span className="cinfo">
                  {details?.selectedTrainers?.map((trainer, index) => (
                    <span key={trainer?.id}>
                      {trainer?.trainerName}
                      {details?.selectedTrainers?.length - 1 !== index && ", "}
                    </span>
                  ))}
                </span>
              </div>

              {memberService !== null &&
                membership_pack == null &&
                authDetails?.authDetails &&
                !groupClassDetail[0]?.campDaysBookTogether &&
                !groupClassDetail[0]?.isCamp && (
                  <div className="cinfo-div">
                    <span className="cinfo-title">
                      <i className="bx bx-money"></i>Credits Remaining for{" "}
                      {details?.serviceName}:{" "}
                    </span>
                    <span className="cinfo">
                      {loading ? "Loading....." : remainingCredits}
                    </span>
                  </div>
                )}
              {membership_pack != null && (
                <div className="cinfo-div">
                  <span className="cinfo-title">
                    <i className="bx bx-money"></i>Credits Remaining for{" "}
                    {details?.serviceName}:{" "}
                  </span>
                  <span className="cinfo">
                    {loading === true && "Loading....."}
                    {isUnlimited === true &&
                      loading !== true &&
                      "Unlimited membership pack active"}
                    {isUnlimited !== true &&
                      loading !== true &&
                      remainingCredits}
                  </span>
                </div>
              )}
              
            </div>
          </div>
        </div>
      </Modal>
      <ToastNotification />
    </>
  );
};

export default EventsClassModal;
