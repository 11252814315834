// css
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/custom.css";
import "./assets/css/app.min.css";
import "./assets/css/icons.css";
import "./assets/css/tui-date-picker.min.css";
import "./assets/css/tui-time-picker.min.css";
import "./assets/css/tui-calendar.min.css";
import "./assets/scss/_colors.scss";
import "./assets/scss/_colors.scss";
import "./assets/scss/common.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";

import React, { Suspense } from "react";
import { hot } from "react-hot-loader/root";
import Amplify from "aws-amplify";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/configureStore";
import Routes from "./Routes";
import awsExports from "./aws-exports";
import { ToastNotification } from "./components";
import ReactGA from "react-ga";
import { AppLoader } from "./components";

Amplify.configure(awsExports);
// ReactGA.initialize("G-JJ5VFMNBLG");
// ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.initialize("G-JJ5VFMNBLG", {
  // debug: true,
  titleCase: false,
  // gaOptions: {
  //   userId: 123
  // }
});
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<AppLoader />} persistor={persistor}>
        <ToastNotification />
        <Suspense fallback={<AppLoader />}>
          <Routes />
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default hot(App);
