import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setSelectedFamilyMemberSession } from "../../redux/actions";

const FamilyMemberListSession = () => {
  const companyData = useSelector((state) => state.auth?.companyData);
  const bookingDetails = useSelector((state) => state.booking);

  const fmWithPrimaryMember = [
    {
      isParent: true,
      firstName: companyData?.member?.firstName,
      lastName: companyData?.member?.lastName,
      uuid: "_parent",
    },
  ].concat(companyData?.familyMembers);
  const dispatch = useDispatch();

  //! all show fm if fm true in service selected
  if (!bookingDetails?.service?.fm) {
    return null;
  }
  return (
    <>
      <div className="family_member">
        <>
          <div className="d-flex justify-content-between align-items-center text-dark bg-light text-left px-3 pl-3 my-3 text-dark fw-normal py-2">
            <p>Select a registrant</p>
            <Link to={`/family-members/true`}>
              <button className="btn btn-primary">
                + Add New Family Member
              </button>
            </Link>
          </div>

          <div className="row row-cols-md-5">
            {fmWithPrimaryMember?.map((family) => (
              <div
                className="card rounded-3 shadow-md p-3 border mx-3"
                onClick={() =>
                  dispatch(setSelectedFamilyMemberSession(family?.uuid))
                }
                key={family?.uuid}
              >
                <div className="row">
                  <div className="col-24 ">
                    <div className="col-2">
                      <div className="form-check pl-0 position-absolute m-l-2">
                        <input
                          type="radio"
                          checked={
                            family?.uuid ===
                            bookingDetails?.selectedFmUuidSession
                          }
                          className="form-check-input"
                          onChange={() => null}
                        />
                      </div>
                    </div>
                    <ul className="list-unstyled my-0 ml-3">
                      <li className="p-1">
                        <strong>Full Name : </strong>
                        {family?.firstName} {family?.lastName}
                      </li>
                      {family?.isParent && (
                        <span className="text">(Primary Member)</span>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      </div>
    </>
  );
};

export default FamilyMemberListSession;
