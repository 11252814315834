import { CHECKIN_MODAL_TYPES } from "../../pages/Others/CheckIn/utils";
import {
  CHECKIN_CLASS_DETAILS_FETCH_COMPLETED,
  CHECKIN_SEARCH_MEMBER_STARTED,
  CHECKIN_SEARCH_MEMBER_FINISHED,
  CHECKIN_MEMBER_TO_CLASS_STARTED,
  CHECKIN_MEMBER_TO_CLASS_FINISHED,
  CHECKIN_MODAL_HIDE,
  CHECKIN_DROP_IN_MEMBERS_STARTED,
  CHECKIN_DROP_IN_MEMBERS_FINISHED,
  CHECKIN_WAIVER_SHOW_MODAL,
  CHECKIN_WAIVER_SIGN_IN_STARTED,
  CHECKIN_WAIVER_SIGN_IN_FINISHED,
  CHECKIN_SHOW_SENT_REGISTRATION_EMAIL,
  CHECKIN_DROP_IN_WAIVER_SET_SELECTED_MEMBERS,
  CHECKIN_DROP_IN_SET_INPUT_EMAIL_OR_PHONE,
} from "../actionTypes";

const INITIAL_STATE = {
  loading: {
    checkin: false,
    memberSearch: false,
    page: true,
  },
  classDetails: null,
  members: [], //! class members checked tru/false || tobe updated
  company: null, //!company details, waiver url
  memberDetails: {
    member_packs: [],
    member: null,
    classMember: null,
    familyMembers: [],
    isUnlimited: false,
    isWaiverSigned: false, //! check for dropin waiver
    remaining_cnt: 0,
  }, //! email submit
  checkinUrlKey: "",
  modal: {
    isShowModal: false,
    type: "", //CHECKIN_MODAL_TYPES,
    alertTitle: "",
  },
  selectedCheckinMember: null,
  selectedDropinMembers: [], //! dropin selected members //! Boolean(waiver for dropin)
  dropIn: {
    email: "",
    phone: "",
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECKIN_CLASS_DETAILS_FETCH_COMPLETED:
      return {
        classDetails: action.payload?.class,
        members: action.payload?.members,
        company: action.payload?.company,
        checkinUrlKey: action.payload?.checkinUrlKey,
      };
    case CHECKIN_MEMBER_TO_CLASS_STARTED:
    case CHECKIN_WAIVER_SIGN_IN_STARTED:
      return {
        ...state,
        loading: {
          ...state.loading,
          checkin: true,
        },
      };
    case CHECKIN_MEMBER_TO_CLASS_FINISHED:
      return {
        ...state,
        members: action.payload?.members,
        memberDetails: {
          ...state.memberDetails,
          remaining_cnt: action.payload.remaining_cnt,
          isUnlimited: action.payload.isUnlimited,
        },
        loading: {
          ...state.loading,
          checkin: false,
          submit: false,
          page: false,
        },
        modal: {
          isShowModal: true,
          type: CHECKIN_MODAL_TYPES.CHECKIN_SUCCESS,
          alertTitle: `You're checked in, have a great class!`,
        },
      };
    case CHECKIN_WAIVER_SHOW_MODAL:
      return {
        ...state,
        modal: {
          isShowModal: true,
          type: CHECKIN_MODAL_TYPES.WAIVER,
        },
        selectedCheckinMember: action?.payload,
      };
    case CHECKIN_MODAL_HIDE:
      return {
        ...state,
        modal: {
          isShowModal: false,
        },
        selectedCheckinMember: null,
        memberDetails: null,
        selectedDropinMembers: [],
      };
    case CHECKIN_SEARCH_MEMBER_STARTED:
      return {
        ...state,
        loading: {
          memberSearch: true,
        },
      };
    case CHECKIN_SEARCH_MEMBER_FINISHED:
      return {
        ...state,
        loading: {
          memberSearch: false,
        },
        memberDetails: action.payload.memberDetails,
        modal: {
          isShowModal: true,
          type: action.payload.type,
          alertTitle: action.payload.alertTitle,
        },
      };
    case CHECKIN_WAIVER_SIGN_IN_FINISHED:
      return {
        ...state,
        members: action.payload?.members,
        memberDetails: null,
        loading: {
          checkin: false,
        },
        modal: {
          isShowModal: false,
        },
        selectedCheckinMember: null,
      };
    case CHECKIN_DROP_IN_MEMBERS_STARTED:
      return {
        ...state,
        loading: {
          checkin: true,
        },
      };
    case CHECKIN_DROP_IN_MEMBERS_FINISHED:
      return {
        ...state,
        loading: {
          checkin: false,
        },
        members: action.payload.members,
        memberDetails: {
          ...state.memberDetails,
          member_packs: action.payload.member_packs,
        },
        classDetails: {
          ...state.classDetails,
          remainingSpots: action.payload.classRemainingSpots,
        },
        dropIn: {
          email: "",
          phone: "",
        },
      };
    case CHECKIN_SHOW_SENT_REGISTRATION_EMAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          type: CHECKIN_MODAL_TYPES.SEND_REGISTRATION_EMAIL,
        },
      };
    case CHECKIN_DROP_IN_WAIVER_SET_SELECTED_MEMBERS:
      return {
        ...state,
        selectedDropinMembers: action.payload,
      };
    case CHECKIN_DROP_IN_SET_INPUT_EMAIL_OR_PHONE:
      return {
        ...state,
        dropIn: {
          ...state.dropIn,
          [action.payload.type]: action.payload.value,
        },
      };

    default:
      return state;
  }
};

export default reducer;
