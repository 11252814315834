import {BASE_URL_WEB} from "../../../utility/constants";
import {Auth} from "aws-amplify";
import axios from "axios";
import Moment from "moment";
import {AppLoader} from "../../../components";

export const getAthleteProfileDetails = async (
  id,
  companyId,
  videoId,
  type,
  authLoading
) => {
  console.log("getAthleteProfileDetails", authLoading);
  let response;
  const params_1 = {
    method: "get",
    headers: {
      "Cache-Control": "no-cache",
    },
  };
  try {
    if (authLoading) {
      return <AppLoader />;
    }
    const user = await Auth.currentAuthenticatedUser();
    if (type === "fm") {
      params_1.url = `${BASE_URL_WEB}athlete-profile?fmId=${id}&companyId=${companyId}`;
    }
    if (type === "pm") {
      params_1.url = `${BASE_URL_WEB}athlete-profile?id=${id}&companyId=${companyId}`;
    }
    if (videoId) {
      params_1.url = `${params_1.url}&videoId=${videoId}`;
    }
    if (user?.signInUserSession?.accessToken?.jwtToken) {
      params_1.headers.Authorization = `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`;
    }
  } catch (err) {
    if (authLoading) {
      return <AppLoader />;
    }
    if (type === "fm") {
      params_1.url = `${BASE_URL_WEB}athlete-profile-public?fmId=${id}&companyId=${companyId}`;
    }
    if (type === "pm") {
      params_1.url = `${BASE_URL_WEB}athlete-profile-public?id=${id}&companyId=${companyId}`;
    }
    if (videoId) {
      params_1.url = `${params_1.url}&videoId=${videoId}`;
    }
    console.log("error", err, err.response);
    response = {
      status: err.response?.status,
      data: {message: err.response?.data?.message || "Something went wrong!"},
    };
  }
  const response1 = await axios(params_1);
  console.log("profile get: ", params_1, response1);
  console.log("res", response1);
  response = {status: response1?.status, data: response1?.data};
  return response;
};
const formatPhoneNumber = (phone) => {
  const cleaned = phone.replace(/[^0-9]+/g, "");
  const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  } else {
    return "";
  }
};

export const formatValue = (dataValue, fieldType = null) => {
  let value = dataValue;
  if (typeof dataValue === "object") {
    if (dataValue?.feet !== undefined || dataValue?.inch !== undefined) {
      const {feet, inch} = dataValue || {};
      if (feet && inch) {
        value = (feet ? feet : "0") + "'" + (inch ? inch : "0") + '"';
      } else if (feet) {
        value = (feet ? feet : "0") + "'";
      } else if (inch) {
        value = (inch ? inch : "0") + '"';
      }
    }
    if (dataValue?.format !== undefined) {
      const {format, HH, MM, SS, hh} = dataValue || {};
      if (format === "HH:MM:SS") {
        value =
          (HH ? HH : "00") + ":" + (MM ? MM : "00") + ":" + (SS ? SS : "00");
      }
      if (format === "MM:SS") {
        value = (MM ? MM : "00") + ":" + (SS ? SS : "00");
      }
      if (format === "MM:SS.hh") {
        value =
          (MM ? MM : "00") + ":" + (SS ? SS : "00") + ":" + (hh ? hh : "00");
      }
      if (format === "SS.hh") {
        value = (SS ? SS : "00") + "." + (hh ? hh : "00");
      }
    }
    if (
      dataValue?.street !== undefined ||
      dataValue?.city !== undefined ||
      dataValue?.state !== undefined ||
      dataValue?.zip !== undefined
    ) {
      const {street, city, state, zip} = dataValue || {};
      value =
        (street ? street : "") +
        ", " +
        (city ? city : "") +
        ", " +
        (state ? state : "") +
        " - " +
        (zip ? zip : "");
    }
  }
  if (fieldType === "number") {
    if (value) {
      value = formatPhoneNumber(value);
    } else {
      value = "";
    }
  }
  if (fieldType === "date") {
    if (value) {
      value = Moment(value).format("MM/DD/YYYY");
    } else {
      value = "";
    }
  }
  return value;
};

export const ATHLETE_PROFILE_SHARE_MODAL_TYPES = Object.freeze({
  VIDEO: "VIDEO",
  PROFILE: "PROFILE",
});

// const isVideoLink = (url) => {
//   try {
//     const parsedUrl = new URL(url);

//     if (
//       parsedUrl.hostname.includes("youtube.com") ||
//       parsedUrl.hostname.includes("youtu.be")
//     ) {
//       // YouTube URL
//       return "YouTube";
//     } else if (parsedUrl.hostname.includes("twitter.com")) {
//       // Twitter URL (Check if it contains /status/ which usually includes videos)
//       if (parsedUrl.pathname.includes("/status/")) {
//         return "Twitter";
//       }
//     } else if (parsedUrl.hostname.includes("linkedin.com")) {
//       // LinkedIn URL (Check if it contains /video/ or /posts/ which can have videos)
//       if (
//         parsedUrl.pathname.includes("/video/") ||
//         parsedUrl.pathname.includes("/posts/")
//       ) {
//         return "LinkedIn";
//       }
//     } else if (parsedUrl.hostname.includes("facebook.com")) {
//       // Facebook URL (Check if it contains /videos/)
//       if (parsedUrl.pathname.includes("/videos/")) {
//         return "Facebook";
//       }
//     } else if (parsedUrl.hostname.includes("vimeo.com")) {
//       // Vimeo URL (Vimeo URLs usually contain vimeo.com/ followed by a numeric ID)
//       const pathSegments = parsedUrl.pathname
//         .split("/")
//         .filter((segment) => segment.length > 0);
//       if (pathSegments.length === 1 && !isNaN(pathSegments[0])) {
//         return "Vimeo";
//       }
//     } else if (parsedUrl.hostname.includes("instagram.com")) {
//       // Instagram URL (Check if it contains /p/ or /tv/)
//       if (
//         parsedUrl.pathname.includes("/p/") ||
//         parsedUrl.pathname.includes("/tv/")
//       ) {
//         return "Instagram";
//       }
//     } else if (parsedUrl.hostname.includes("tiktok.com")) {
//       // TikTok URL (Check if it contains /@username/video/)
//       if (
//         parsedUrl.pathname.includes("/@") &&
//         parsedUrl.pathname.includes("/video/")
//       ) {
//         return "TikTok";
//       }
//     }
//   } catch (e) {
//     // Invalid URL
//     return "";
//   }

//   return "";
// };
