import {
  SESSION_TYPE,
  ADD_TRAINER,
  ADD_SERVICE,
  ADD_TRAINING_TIME,
  CONFIRM_BOOKING,
  DEFAULT_BOOKING_PAGE,
  ADD_CLASS_BOOKING_DETAILS,
  ADD_SELECTED_PACKAGE,
  CLEAR_CLASS_BOOKING_DETAILS,
  CLEAR_SERVICE,
  CLEAR_TRAINER,
  CLEAR_TRAINING_TIME,
  PUBLIC_APPOINTMENT_BOOKING,
  CLEAR_APPOINTMENT_DETAILS,
  SET_SELECTED_FAMILY_MEMBERS,
  ADD_CAMP_ANSWERS_IN_SELECTED_CHILD,
  ADD_COUPON_CODE,
  SET_SELECTED_FAMILY_MEMBER_SESSION,
  BOOKING_SESSION_SET_TIME,
  BOOKING_SESSION_SET_DATE,
} from "../actionTypes";

export const setSessionTrainingTime = (payload) => {
  return {
    type: BOOKING_SESSION_SET_TIME,
    payload,
  };
};

export const setSessionTrainingDate = (payload) => {
  return {
    type: BOOKING_SESSION_SET_DATE,
    payload,
  };
};

export const changeSessionPage = (state) => {
  return {
    type: SESSION_TYPE,
    session_type: state,
  };
};

export const changeServicePage = (state) => {
  return {
    type: ADD_SERVICE,
    service_data: state,
  };
};

export const changeClientPage = (state) => {
  return {
    type: ADD_TRAINER,
    trainer_data: state,
  };
};

export const changeTrainingTimePage = (state) => {
  return {
    type: ADD_TRAINING_TIME,
    time: state,
  };
};

export const confirmBookingPage = () => {
  return {
    type: CONFIRM_BOOKING,
  };
};

export const setDefaultBookingPage = () => {
  return {
    type: DEFAULT_BOOKING_PAGE,
  };
};

export const addClassBookingDetails = (state) => {
  return {
    type: ADD_CLASS_BOOKING_DETAILS,
    payload: state,
  };
};

export const addSelectedPackage = (state) => {
  return {
    type: ADD_SELECTED_PACKAGE,
    package: state,
  };
};

export const clearClassBookingDetails = () => {
  return {
    type: CLEAR_CLASS_BOOKING_DETAILS,
  };
};

export const clearService = () => {
  return {
    type: CLEAR_SERVICE,
  };
};
export const clearTrainer = () => {
  return {
    type: CLEAR_TRAINER,
  };
};
export const clearTimeSlots = () => {
  return {
    type: CLEAR_TRAINING_TIME,
  };
};
export const verifyCouponCode = (payload) => {
  return {
    type: ADD_COUPON_CODE,
    payload,
  };
};
export const publicAppointmentBooking = (state) => {
  return {
    type: PUBLIC_APPOINTMENT_BOOKING,
    payload: state,
  };
};

export const clearAppointmentBookingDetails = () => {
  return {
    type: CLEAR_APPOINTMENT_DETAILS,
  };
};

export const setSelectedFamilyMembers = (payload) => {
  return {
    type: SET_SELECTED_FAMILY_MEMBERS,
    payload,
  };
};
export const setSelectedFamilyMemberSession = (payload) => {
  return {
    type: SET_SELECTED_FAMILY_MEMBER_SESSION,
    payload,
  };
};
// export const setCampQuestions = (payload) => {
//   return {
//     type: SET_CAMP_QUESTIONS,
//     payload,
//   };
// };
export const addCampAnswersInSelectedChild = (payload) => {
  return {
    type: ADD_CAMP_ANSWERS_IN_SELECTED_CHILD,
    payload,
  };
};
