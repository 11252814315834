import {
  REDIRECT_PUBLIC_USER,
  CLEAR_PUBLIC_USER,
  PUBLIC_BRANCH_CHANGE,
} from "../actionTypes";

const INITIAL_STATE = {
  companyId: null,
  sessionId: null,
  classId: null,
  branch: [],
  companyImage: "",
  companyName: "",
  showKidsAfterSignup: null,
  eventDate: null,
  branchSelected: null,
  haveKids: null,
  isFromCheckIn: false,
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDIRECT_PUBLIC_USER:
      return {
        ...state,
        companyId: action.payload.companyId,
        classId: action.payload.classId,
        branch: action.payload.branchDetails,
        companyImage: action.payload.imageUrl,
        showKidsAfterSignup: action.payload.showKidsAfterSignup,
        eventDate: action.payload.eventDate,
        haveKids: action.payload.haveKids,
        companyName: action.payload.companyName,
      };
    case CLEAR_PUBLIC_USER:
      return {
        ...state,
        companyId: null,
        classId: null,
        branch: [],
        companyImage: "",
        showKidsAfterSignup: null,
        eventDate: null,
        haveKids: null,
        companyName: "",
        isFromCheckIn: false,
      };
    case PUBLIC_BRANCH_CHANGE:
      return {
        ...state,
        companyId: action.payload.companyId,
        sessionId: action.payload.sessionId,
        branchSelected: action.payload.branchSelected,
        isFromCheckIn: !!action.payload.isFromCheckIn,
      };

    default:
      return state;
  }
};

export default reducer;
