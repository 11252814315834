/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:d0cde4d8-161f-4e7e-85d5-61d439533308",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_GndMGKG4M",
  aws_user_pools_web_client_id: "4fqpp23rbd7fhv05rtvbass853",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://32garhzgj5eutixue4lc444uiu.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_user_files_s3_bucket:
    "circadn5c3ef53d0ecb465fbc5b20293d2e9f5a134820-circadn",
  aws_user_files_s3_bucket_region: "us-east-1",
};

export default awsmobile;
