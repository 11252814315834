import { Button } from "antd";

export default function AppRedBtn({
  _onClickBtn,
  loading,
  disabled,
  text,
  className,
}) {
  const buttonTextStyle = {
    whiteSpace: "normal",
  };
  const buttonStyle = {
    backgroundColor: disabled ? `#db7a85` : `#c72638`,
    color: "white",
    transition: "background-color 0.3s, border-color 0.3s, color 0.3s", // Add transition for smooth hover effect
    ":hover": {
      backgroundColor: "black",
      border: "1px solid white",
      color: "white",
    },
    height: "auto",
    width: "100%",
  };

  return (
    <Button
      type="primary"
      size="small"
      onClick={_onClickBtn}
      loading={loading}
      disabled={disabled}
      style={buttonStyle}
      className={`font-size-16 ${className} mb-2 p-1`}
    >
      <span style={buttonTextStyle}>{text}</span>
    </Button>
  );
}
