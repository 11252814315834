import React from "react";
import Lottie from "react-lottie";
import { defaultLoaderOptions } from "../../common/config";

const AppLoader = ({ height, width }) => (
  <Lottie
    options={defaultLoaderOptions}
    height={height || 250}
    width={width || 250}
  />
);

export default AppLoader;
