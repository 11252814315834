import {
  ADD_COMPANY_DATA,
  UPDATE_FAMILY_MEMBERS,
  AUTH_USER,
  CLEAR_AUTH_DATA,
  CLEAR_COMPANY_DATA,
  GET_COMPANY_DATA,
  HIDE_COMPANY_LOAD,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  SHOW_AUTH_LOAD,
  SHOW_COMPANY_LOAD,
  UPDATE_COMPANY_WAIVER_SIGNED,
  UPDATE_COMPANY_MEMBER_TNC,
} from "../actionTypes";

const INITIAL_STATE = {
  authDetails: null,
  user: null,
  error: "",
  companyData: null,
  authLoading: { status: false, err: "" },
  companyDataLoadingStatus: true,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER:
      // console.log("reducer data is here: ", action.authDetails);
      return {
        ...state,
        authDetails: action.authDetails,
        authLoading: action.payload,
        companyDataLoadingStatus: true,
      };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.userDetails,
        authLoading: action.payload,
      };

    case LOGIN_USER_FAILURE:
      return {
        ...state,
        error: "email or password is incorrect",
        authLoading: action.payload,
      };
    case GET_COMPANY_DATA:
      return {
        ...state,
        companyData: action.payload,
        companyDataLoadingStatus: false,
      };
    case SHOW_AUTH_LOAD:
      return {
        ...state,
        authLoading: action.payload,
      };
    case CLEAR_AUTH_DATA:
      return {
        authDetails: null,
        user: null,
        error: "",
        companyData: null,
        authLoading: { status: false, err: "" },
        companyDataLoadingStatus: true,
      };
    case CLEAR_COMPANY_DATA:
      return {
        ...state,
        companyData: null,
      };
    case SHOW_COMPANY_LOAD:
      return {
        ...state,
        companyDataLoadingStatus: true,
      };
    case ADD_COMPANY_DATA:
      return {
        ...state,
        companyData: action.payload,
      };
    case HIDE_COMPANY_LOAD:
      return {
        ...state,
        companyDataLoadingStatus: false,
      };
    case UPDATE_FAMILY_MEMBERS:
      return {
        ...state,
        companyData: {
          ...state.companyData,
          familyMembers: action.payload,
        },
      };
    case UPDATE_COMPANY_WAIVER_SIGNED:
      return {
        ...state,
        companyData: {
          ...state.companyData,
          waiver_signed: true,
        },
      };
    case UPDATE_COMPANY_MEMBER_TNC:
      return {
        ...state,
        companyData: {
          ...state.companyData,
          member: {
            ...state.companyData.member,
            toc: Date.now(),
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
