import moment from "moment";
import {
  ADD_TRAINER,
  ADD_SERVICE,
  ADD_TRAINING_TIME,
  CONFIRM_BOOKING,
  SESSION_TYPE,
  DEFAULT_BOOKING_PAGE,
  ADD_CLASS_BOOKING_DETAILS,
  ADD_SELECTED_PACKAGE,
  CLEAR_CLASS_BOOKING_DETAILS,
  CLEAR_SERVICE,
  CLEAR_TRAINER,
  CLEAR_TRAINING_TIME,
  PUBLIC_APPOINTMENT_BOOKING,
  CLEAR_APPOINTMENT_DETAILS,
  SET_SELECTED_FAMILY_MEMBERS,
  ADD_CAMP_ANSWERS_IN_SELECTED_CHILD,
  ADD_COUPON_CODE,
  SET_SELECTED_FAMILY_MEMBER_SESSION,
  BOOKING_SESSION_SET_TIME,
  BOOKING_SESSION_SET_DATE,
} from "../actionTypes";

const INITIAL_STATE = {
  session_type: null,
  service: null,
  trainer: null,
  trainingTime: {
    date: moment().format("YYYY-MM-DD"),
    time: "",
  },
  activeBookingPage: "SESSION_TYPE",
  classDetails: null,
  selectedPackage: null,
  // campQuestions: null,
  selectedFamilyMembers: [],
  couponCode: {
    isValid: null, //! null -> api not called | true -> valid | false -> not valid
    couponDetails: null,
  },
  selectedFmUuidSession: "_parent", //! select default as parent
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SESSION_TYPE:
      // console.log("reducer data is here: ", action.authDetails);
      return {
        ...state,
        session_type: action.session_type,
        activeBookingPage: ADD_SERVICE,
      };

    case ADD_SERVICE:
      return {
        ...state,
        service: action.service_data,
        activeBookingPage: ADD_TRAINER,
      };

    case ADD_TRAINER:
      return {
        ...state,
        trainer: action.trainer_data,
        activeBookingPage: ADD_TRAINING_TIME,
      };
    case ADD_TRAINING_TIME:
      return {
        ...state,
        activeBookingPage: CONFIRM_BOOKING,
        couponCode: {
          isValid: null,
          couponDetails: null,
        },
        selectedPackage: null,
      };
    case BOOKING_SESSION_SET_TIME:
      return {
        ...state,
        trainingTime: {
          ...state.trainingTime,
          time: action.payload,
        },
      };
    case BOOKING_SESSION_SET_DATE:
      return {
        ...state,
        trainingTime: {
          ...state.trainingTime,
          date: action.payload,
        },
      };
    case CONFIRM_BOOKING:
      return {
        ...state,
      };
    case DEFAULT_BOOKING_PAGE:
      return {
        ...state,
        activeBookingPage: SESSION_TYPE,
      };
    case ADD_CLASS_BOOKING_DETAILS:
      return {
        ...state,
        classDetails: action.payload,
        service: null,
        trainer: null,
        selectedPackage: null,
        couponCode: {
          isValid: null,
          couponDetails: null,
        },
      };
    case ADD_SELECTED_PACKAGE:
      return {
        ...state,
        selectedPackage: action.package,
      };
    case CLEAR_CLASS_BOOKING_DETAILS:
      return {
        ...state,
        classDetails: null,
        // classDetails: null
        selectedPackage: null,
        selectedFamilyMembers: null,
        couponCode: {
          isValid: null,
          couponDetails: null,
        },
      };
    case CLEAR_SERVICE:
      return {
        ...state,
        service: null,
      };
    case CLEAR_TRAINER:
      return {
        ...state,
        trainer: null,
      };
    case CLEAR_TRAINING_TIME:
      return {
        ...state,
        trainingTime: {
          date: moment().format("YYYY-MM-DD"),
          time: "",
        },
      };
    case PUBLIC_APPOINTMENT_BOOKING:
      return {
        ...state,
        service: action.payload.service,
        trainer: action.payload.trainer,
        session_type: "PUBLIC_APPOINTMENT_BOOKING",
        activeBookingPage: ADD_TRAINING_TIME,
      };
    case CLEAR_APPOINTMENT_DETAILS:
      return {
        ...state,
        session_type: null,
        service: null,
        trainer: null,
        trainingTime: {
          date: moment().format("YYYY-MM-DD"),
          time: "",
        },
        activeBookingPage: "",
        selectedPackage: null,
        classDetails: null,
        // campQuestions: null,
        selectedFamilyMembers: [],
        selectedFmUuidSession: "_parent",
        couponCode: {
          isValid: null,
          couponDetails: null,
        },
      };
    // case SET_CAMP_QUESTIONS:
    //   return {
    //     ...state,
    //     campQuestions: action.payload,
    //   };
    case SET_SELECTED_FAMILY_MEMBERS:
      return {
        ...state,
        selectedFamilyMembers: action.payload,
      };
    case SET_SELECTED_FAMILY_MEMBER_SESSION:
      return {
        ...state,
        selectedFmUuidSession: action.payload,
      };
    case ADD_CAMP_ANSWERS_IN_SELECTED_CHILD: {
      const orgFamilyMem = [...state.selectedFamilyMembers];
      let index = orgFamilyMem.findIndex(
        (child) => child.uuid === action.payload.uuid
      );
      orgFamilyMem[index] = action.payload;
      return {
        ...state,
        selectedFamilyMembers: orgFamilyMem,
      };
    }
    case ADD_COUPON_CODE:
      return {
        ...state,
        couponCode: {
          ...state.couponCode,
          couponDetails: action.payload?.couponDetails,
          isValid: action.payload?.isValid,
        },
      };
    default:
      return state;
  }
};

export default reducer;
