import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppLoader } from "..";
import PublicSessionsCardList from "./components/PublicSessionsCardList";
import PublicCoachesList from "./components/PublicCoachesList";

/**
 * 2 sided view sessions | coaches -> public page
 * 1 side view session -> loggedin user
 */
const PublicAppointments = () => {
  const authDetails = useSelector((state) => state.auth);
  const publicUser = useSelector((state) => state.publicUser);
  const bookingDetails = useSelector((state) => state.booking);
  const [servicesList, setServicesList] = useState({
    list: [],
    isLoading: true,
  });
  const [trainerList, setTrainerList] = useState([]);
  const [selectedService, setSelectedService] = useState({});

  useEffect(() => {
    if (authDetails?.authDetails) {
      let services = [];
      authDetails?.companyData?.services?.service.forEach((item) => {
        services.push({
          service: item,
          trainer: item?.selectedTrainers,
        });
      });
      setServicesList({ list: services, isLoading: false });
    } else {
      authDetails.companyData && getServices();
    }
    if (authDetails.authDetails && bookingDetails.service) {
      setSelectedService(bookingDetails?.service);
    }
  }, [authDetails.companyData, publicUser]);

  const getServices = () => {
    //! filter trainers on the basis of selected branch, param branchId
    const filteredBranchTrainerDetails = Object.values(
      authDetails.companyData?.trainers?.items || {}
    )?.filter(
      (trainer) =>
        trainer?.branchTrainersBranchId === publicUser?.branchSelected?.id
    );
    console.log("filteredBranchTrainerDetails", filteredBranchTrainerDetails);

    //! get those trainers branchTrainersTrainerId
    const branchTrainersId = filteredBranchTrainerDetails?.map(
      (trainer) => trainer.branchTrainersBranchId
    );

    // //! get trainers on the basis of service they are in
    const updatedServices = authDetails.companyData?.services?.service?.map(
      (service) => {
        if (service?.uuid === publicUser?.sessionId) {
          setSelectedService(service);
        }
        let trainer = service?.selectedTrainers?.filter((trainer) => {
          for (let id in branchTrainersId) {
            return trainer?.branchTrainersBranchId === branchTrainersId[id];
          }
        });
        setTrainerList(trainer);
        const selectedServiceTrainers = filteredBranchTrainerDetails.filter(
          (item) => {
            return trainer.some((trainerItem) => {
              return (
                item.branchTrainersTrainerId ===
                trainerItem?.branchTrainersTrainerId
              );
            });
          }
        );
        return {
          service,
          trainer: selectedServiceTrainers,
        };
      }
    );

    const hasTrainer = updatedServices?.some((item) => item.trainer.length > 0);
    setServicesList({
      list: hasTrainer ? updatedServices : [],
      isLoading: false,
    });
  };

  return authDetails.companyDataLoadingStatus || servicesList?.isLoading ? (
    <AppLoader />
  ) : (
    <div className="select_coach">
      <div className="page-wrap row">
        <div className="page-content col-md-9">
          <section className="amazing_feature">
            <div className="col-12 row">
              <div className="col">
                <h2 className="heading mt-4 mb-4 font-size-20 text-uppercase text-center border-bottom pb-2">
                  Services
                </h2>
                <PublicSessionsCardList
                  servicesList={servicesList.list}
                  setTrainerList={setTrainerList}
                  setSelectedService={setSelectedService}
                  selectedService={selectedService}
                />
              </div>

              {!authDetails?.authDetails &&
                servicesList?.list?.length > 0 &&
                Object.keys(selectedService)?.length > 0 && (
                  <div className="col-4">
                    <h2 className="heading mt-4 mb-4 font-size-20 text-uppercase text-center border-bottom pb-2">
                      Coaches
                    </h2>
                    <PublicCoachesList
                      trainerList={trainerList}
                      selectedService={selectedService}
                    />
                  </div>
                )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PublicAppointments;
