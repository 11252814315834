import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import {
  SignIn,
  ForgotPassword,
  PublicBooking,
  CheckIn,
  Waiver,
  PostSignUp,
  AthleteProfile,
} from "../pages";
import PrivateRoute from "./privateRoutes";
import PublicRoute from "./publicRoutes";
import routeConfig from "./routeConfig";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute
          restricted={true}
          component={SignIn}
          path={[
            "/login",
            "/user-verify/:type/:uuid/:key",
            "/user-verify-phone/:type/:uuid/:key",
            "/auto-login/:type/:uuid/:key",
            "/sign-up",
            "/client-register/:type/:uuid/:key",
          ]}
          exact
        />
        <PublicRoute
          restricted={true}
          component={PostSignUp}
          path="/post-sign-up"
          exact
        />
        <PublicRoute
          restricted={true}
          component={ForgotPassword}
          path="/forgot-password"
          exact
        />
        <PublicRoute
          restricted={true}
          component={Waiver}
          path="/sign-waiver"
          exact
        />
        {/* //! will be used by coaches */}
        <PublicRoute
          restricted={false}
          component={CheckIn}
          path="/client-self-checkin"
          exact
        />
        <PublicRoute
          restricted={false}
          component={PublicBooking}
          path={["/class-booking", "/public-booking/:id"]}
          exact
        />
        <PublicRoute
          restricted={false}
          component={AthleteProfile}
          path={`/${AthleteProfile.route}`}
          exact
        />

        {routeConfig.map((routes, index) => (
          <PrivateRoute {...routes} key={index} />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
