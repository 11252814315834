import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
// import thunkMiddleware from "redux-thunk";
import { thunk } from "redux-thunk";

// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import rootReducer from "./reducer";
import loggerMiddleware from "./middleware/logger";

const middlewareEnhancer = applyMiddleware(loggerMiddleware, thunk);

const store = createStore(rootReducer, undefined, middlewareEnhancer);
const persistor = persistStore(store);

export { store, persistor };
