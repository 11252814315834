import React from "react";
import { Card } from "antd";
import {
  changeServicePage,
  clearClassBookingDetails,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../public-appointment.scss";

const PublicSessionsCardList = ({
  servicesList,
  setTrainerList,
  setSelectedService,
  selectedService,
}) => {
  const dispatch = useDispatch();
  const authDetails = useSelector((state) => state.auth);
  const history = useHistory();
  return (
    <div className="row">
      {servicesList?.length > 0 ? (
        servicesList?.map(({ service, trainer }) => (
          <Card
            className={`card-session ${
              service?.uuid === selectedService?.uuid && "selected-card"
            }`}
            onClick={() => {
              if (authDetails.authDetails) {
                dispatch(changeServicePage(service));
                dispatch(clearClassBookingDetails());
                history.push("/booking");
              } else {
                setTrainerList(trainer);
                setSelectedService(service);
              }
            }}
            key={service?.uuid}
            hoverable
            style={{ width: 300, marginRight: 25, marginBottom: 25 }}
          >
            <Card.Meta
              title={
                <span className="font-size-18 txt">{service?.serviceName}</span>
              }
              description={
                <p className="service-scroll font-size-15 txt">
                  {service?.description}
                </p>
              }
            />
          </Card>
        ))
      ) : (
        <h3>No services available</h3>
      )}
    </div>
  );
};

export default PublicSessionsCardList;
