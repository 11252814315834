import React from "react";
import {Link} from "react-router-dom";

const ButtonMailto = ({mailto, children}) => {
  let type = "phone";
  if (mailto?.includes("@")) {
    type = "email";
  }

  return (
    <Link
      to="#"
      onClick={(e) => {
        if (type === "email") {
          window.location.href = `mailto:${mailto}`;
        } else {
          window.location.href = `tel:${mailto}`;
        }
        e.preventDefault();
      }}
    >
      {children}
    </Link>
  );
};

export default ButtonMailto;
