import { Auth } from "aws-amplify";
import axios from "axios";
import Moment from "moment";
import { notification } from "antd";
import {
  ANT_DESIGN_NOTIFICATION_TYPES,
  calendarDateRange,
} from "../common/config";

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const getPMAge = (dob) => {
  console.log("dob", dob);
  if (dob) {
    const d = new Date(dob);
    const now = new Date();
    const diff = now - d;
    const age = Math.floor(diff / 31536000000);
    console.log("age", age);
    return age;
  }
  return 0;
};
const cognitoErrCodeReturnMsg = (err) => {
  let msg = "";
  const { code, message } = err;
  switch (code) {
    case "UserNotFoundException":
      msg = "We can't find your email, please check again.";
      break;
    case "UsernameExistsException":
      msg = "This email address already in use.";
      break;
    case "UserNotConfirmedException":
      msg = "Account not verified yet";
      break;
    case "PasswordResetRequiredException":
      msg = "Existing user found. Please reset your password";
      break;
    case "NotAuthorizedException":
      msg = message;
      break;
    case "CodeMismatchException":
      msg = "Please try again wrong OTP";
      break;
    case "InvalidParameterException":
      msg = message;
      break;
    case "LimitExceededException":
      msg = message;
      break;
    default:
      msg = `Something error happened, please try again. ${code}`;
      break;
  }
  return msg;
};

const AxiosHelper = async (
  url,
  method,
  userId,
  userType = "member",
  data = null
) => {
  if (url.charAt(url.length - 1) !== "?") {
    url += "&";
  }
  if (userType === "member") {
    url += "memberId=" + userId;
  }

  const user = await Auth.currentAuthenticatedUser();
  console.log("current_user", user);
  console.log(url);
  const API_AUTH = {
    username: userType,
    password: user?.signInUserSession?.accessToken?.jwtToken,
  };
  const params_1 = {
    method: method,
    url,
    auth: API_AUTH,
  };
  console.log("AxiosHelper: ", params_1);
  if (data !== null) {
    params_1.data = data;
  }
  const response = await axios(params_1);
  return response;
};

const AxiosHelperPortal = async (url, method, data = null) => {
  if (url.charAt(url.length - 1) !== "?") {
    url += "&";
  }
  console.log(url);
  const user = await Auth.currentAuthenticatedUser();
  let params_1 = {
    method: method,
    url,
    headers: {
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
    },
  };
  console.log(params_1);
  if (data !== null) {
    params_1.data = data;
  }
  const response = await axios(params_1);
  console.log("AxiosHelperCI url: ", url, response);
  return response;
};

const dateFormatter = (date, format) => {
  if (typeof date === "string") date = new Moment(date);

  let dd = date.date();
  let mm = date.month();
  const yyyy = date.year();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let prefix = "";
  if (dd === 1) prefix = "st";
  else if (dd === 2) prefix = "nd";
  else if (dd === 3) prefix = "rd";
  else prefix = "th";
  let formattedDate = "";

  switch (format) {
    case 1:
      mm += 1;
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      formattedDate = yyyy + "-" + mm + "-" + dd;
      return formattedDate;
    case 2:
      formattedDate = months[mm] + " " + dd + prefix + ", " + yyyy;
      return formattedDate;
    default:
      return date;
  }
};

const getInitials = (fName, lName) => {
  if (!fName) {
    return "NULL";
  }

  let name = fName;
  if (lName && typeof lName !== "undefined" && lName.trim() !== "") {
    name += " " + lName;
  }
  const nameArr = name.split(" ");
  let text = nameArr[0][0];
  if (nameArr.length > 1) {
    text += nameArr[1][0];
  }
  return text.toUpperCase();
};
/**
 * Not in use
 */
// const secondsToHms = (seconds) => {
//   seconds = Number(seconds);
//   const h = Math.floor((seconds % (3600 * 24)) / 3600);
//   const m = Math.floor((seconds % 3600) / 60);
//   const s = Math.floor(seconds % 60);

//   return {
//     hours: h,
//     minutes: m,
//     seconds: s,
//   };
// };
/**
 * Not in use
 */
// const sendNotification = (payload) => {
//   axios({
//     method: "post",
//     url: NODE_LAMBDA + "appointmentNotification",
//     data: payload,
//   })
//     .then((res) => console.log(res))
//     .catch((err) => console.log(err));
// };

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener");
  if (newWindow) newWindow.opener = null;
};

const getCompanyInitials = (name) => {
  if (typeof name !== "string") return name;
  let separatedName = name.split(" ");
  let initials = separatedName.map((item) => item[0]);
  return initials.join("").toUpperCase();
  // console.log(initials);
};

const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString?.includes("+")) {
    return phoneNumberString;
  }
  const keepOnlyDig = phoneNumberString?.replace(/\D/g, "");
  const passToMatcher = "+" + keepOnlyDig;
  const match = passToMatcher?.match(
    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/
  );
  if (match) {
    const intlCode = match[1] ? match[1] : "";
    const intlCodeRemoveForUs = intlCode === "+1" ? "" : intlCode;
    return [
      intlCodeRemoveForUs,
      " (",
      match[2],
      ") ",
      match[6],
      "-",
      match[8],
    ].join("");
  }
  return phoneNumberString;
};

const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

const calcSquareProcessingFee = (amount, fee_percent = null) => {
  if (fee_percent === null) {
    fee_percent = 0.2;
  }
  amount = parseFloat(amount);
  let app_fee = (amount * fee_percent) / 100;
  let total = app_fee + (amount * 2.45) / 100 + 0.3;
  return Math.round((total + Number.EPSILON) * 100) / 100;
};

//!calendar
function getCalendarDates(
  start = calendarDateRange.start,
  end = calendarDateRange.end
) {
  const startDate = start;
  const endDate = end;
  const now = startDate.clone();
  const dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("MM-DD-YYYY"));
    now.add(1, "days");
  }
  return dates;
}

function calcClassRemainingSpots(data) {
  if (data.signupCount) {
    if (data.physicalCapacity - data.signupCount <= 0) {
      return "Class Full";
    } else {
      return data.physicalCapacity - data.signupCount;
    }
  } else {
    if (data.physicalCapacity !== null && data.physicalCapacity) {
      return data.physicalCapacity;
    } else {
      return null;
    }
  }
}

const showToast = {
  [ANT_DESIGN_NOTIFICATION_TYPES.WARNING]: (message, title) =>
    notification[ANT_DESIGN_NOTIFICATION_TYPES.WARNING]({
      message: title,
      description: message,
    }),
  [ANT_DESIGN_NOTIFICATION_TYPES.SUCCESS]: (message, title) =>
    notification[ANT_DESIGN_NOTIFICATION_TYPES.SUCCESS]({
      message: title,
      description: message,
    }),
  [ANT_DESIGN_NOTIFICATION_TYPES.ERROR]: (message, title) =>
    notification[ANT_DESIGN_NOTIFICATION_TYPES.ERROR]({
      message: title,
      description: message,
    }),
};

function getUrlExtension(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim();
}

// export const genDropdownPickerItemsFormat = (array) =>
//   array.reduce((a, v) => [...a, { value: v }], []);

export {
  getPMAge,
  validateEmail,
  cognitoErrCodeReturnMsg,
  AxiosHelper,
  AxiosHelperPortal,
  dateFormatter,
  getInitials,
  // secondsToHms,
  // sendNotification,
  openInNewTab,
  getCompanyInitials,
  formatPhoneNumber,
  tConvert,
  calcSquareProcessingFee,
  getCalendarDates,
  calcClassRemainingSpots,
  showToast,
  getUrlExtension,
};
