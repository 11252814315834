import React from "react";

const Footer = () => (
  <footer className="footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          {new Date().getFullYear()} © CoachFirst.
        </div>
        {/* <div className="col-sm-6">
            <div className="text-sm-end d-none d-sm-block">
              {"Design & Develop by Themesbrand"}
            </div>
          </div> */}
      </div>
    </div>
  </footer>
);

export default Footer;
