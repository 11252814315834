import React from "react";
import {Popover} from "antd";
import {
  formatPhoneNumber,
  getInitials,
} from "../../../../utility/helperFunctions";
import ButtonMailto from "../../../../components/ContactUs/ButtonMailto";

const TeamHistory = (company) => {
  return (
    <div className="list-grp">
      <div className="list-items">
        {company?.company?.logo ? (
          <img src={company?.company?.logo} alt="logo" />
        ) : (
          <span className="avName" style={{height: "70px", width: "70px"}}>
            <span className="proName">
              {company?.company?.name && getInitials(company?.company?.name)}
            </span>
          </span>
        )}
        <div className="details">
          <p style={{fontSize: "18px", fontWeight: "bold"}}>
            {company?.company?.name}
          </p>
          <ButtonMailto mailto={formatPhoneNumber(company?.company?.contact)}>
            <Popover
              content={
                <div>
                  <p>{formatPhoneNumber(company?.company?.contact)}</p>
                </div>
              }
            >
              <h3
                className={`light athlete ${
                  formatPhoneNumber(company?.company?.contact)?.includes("+") ? "truncate" : ""
                }`}
              >
                {formatPhoneNumber(company?.company?.contact)}
              </h3>
            </Popover>
          </ButtonMailto>
        </div>
      </div>
    </div>
  );
};

export default TeamHistory;
