import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeServicePage,
  clearService,
  setDefaultBookingPage,
} from "../../redux/actions/index";
import SweetAlert from "react-bootstrap-sweetalert";
import BookingSteps from "../BookingSteps";

const ServiceSelect = () => {
  const [service, setService] = useState(null);
  const dispatch = useDispatch();
  const authDetails = useSelector((state) => state.auth);
  const [alertShow, setAlertShow] = useState({
    show: false,
    msg: "",
  });
  const serviceList = authDetails.companyData?.services?.service;
  const bookingDetails = useSelector((state) => state.booking);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    bookingDetails.service && setService(bookingDetails.service);
  }, []);

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container">
            {/* <!-- end page title -->
                
            <!-- Start : Session Booking --> */}
            <div className="_sessionBookingPage bg-white">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-xl-12 mx-auto">
                    <div className="_cardTop-hdr mb-4 text-center page-title">
                      <h2 className="_text-dark">Choose Service</h2>
                      {/* <p>
                        lorem ipsum lorem ipsumlorem ipsumlorem ipsum lorem
                        ipsum{" "}
                      </p> */}
                    </div>

                    <BookingSteps />

                    {serviceList?.length ? (
                      <>
                        <div className="_bookingTable-outter _list-outter list_grid">
                          {/* <!-- List Begin  ---> */}
                          <ul className="_clientLists">
                            {serviceList?.map((data, index) => (
                              <li className="_clientList mb-3" key={index}>
                                <label className="_b-label">
                                  <input
                                    type="radio"
                                    name="service"
                                    checked={data?.uuid === service?.uuid}
                                    onClick={() => setService(data)}
                                  />
                                  <div className="_clientList-div  _rightArr">
                                    <div className="_clCont">
                                      <p className="_name pl-0">
                                        {" "}
                                        {data.serviceName}
                                      </p>
                                      <p className="_info">
                                        <i className="bx bx-time-five"></i>{" "}
                                        Duration: {data.duration}min
                                      </p>
                                      {/* <p className="_info">
                                        {" "}
                                        <i className="bx bx-purchase-tag-alt"></i>
                                        {data.categoryName}
                                      </p> */}
                                    </div>
                                  </div>
                                </label>
                              </li>
                            ))}
                          </ul>
                          {/* <!-- List End  ---> */}
                        </div>

                        {/* <div className="text-center booking_btns">
                          <button
                            className="btn btn-round left"
                            onClick={() => dispatch(setDefaultBookingPage())}
                          >
                            <i
                              className="fas fa-chevron-left"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            className="btn btn-round right"
                            onClick={() => {
                              if (service === null) {
                                console.log(service);
                                setAlertShow({
                                  show: true,
                                  msg: "please select a service",
                                });
                              } else {
                                dispatch(changeServicePage(service));
                              }
                            }}
                          >
                            <i
                              className="fas fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div> */}
                      </>
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <h5>No services available</h5>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="_btnGrp text-center py-4">
                <button
                  className="btn btn-outline-danger px-5"
                  onClick={() => {
                    dispatch(setDefaultBookingPage());
                    dispatch(clearService());
                  }}
                >
                  Back
                </button>
                <button
                  className="btn btn-primary px-5"
                  disabled={!serviceList.length}
                  onClick={() => {
                    if (service === null) {
                      console.log(service);
                      setAlertShow({
                        show: true,
                        msg: "please select a service",
                      });
                    } else {
                      dispatch(changeServicePage(service));
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          {/* <!-- container --> */}
        </div>
      </div>
      {alertShow.show && (
        <SweetAlert
          danger
          title="Woot!"
          onConfirm={() => setAlertShow({ ...alertShow, show: false })}
        >
          {alertShow.msg}
        </SweetAlert>
      )}
    </>
  );
};

export default ServiceSelect;
