import { useEffect, useState } from "react";
import { Dropdown, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addCampAnswersInSelectedChild } from "../../../redux/actions";
import AppDatePicker from "../../AppDatePicker/AppDatePicker";
import PhoneInput from "react-phone-input-2";
import { InputNumber } from "antd";
import moment from "moment";

const CAMP_Q_TYPES = {
  TEXT: "Text",
  TEXTAREA: "Textarea",
  DROPDOWN: "Dropdown",
  PHONE: "phone",
  NUMBER: "Number",
  DATE: "Date",
};

function CampQuestions({ campQuestions }) {
  const dispatch = useDispatch();
  const bookingDetails = useSelector((state) => state.booking);
  const [childPaginationPos, setChildPaginationPos] = useState(0);
  const selectedChildId =
    bookingDetails?.selectedFamilyMembers[childPaginationPos]?.uuid;
  const selectedChild =
    bookingDetails?.selectedFamilyMembers[childPaginationPos];

  //! reset child pos; if selected fm altered
  useEffect(() => {
    setChildPaginationPos(0);
  }, [bookingDetails?.selectedFamilyMembers.length]);

  const _handleOnChangeInput = ({ value, selectedQuestionUuid, question }) => {
    let childItem =
      bookingDetails?.selectedFamilyMembers[childPaginationPos] || {};
    if (
      "questions" in childItem &&
      childItem?.questions[selectedQuestionUuid]
    ) {
      childItem.questions[selectedQuestionUuid].a = value;
    } else {
      // edit question
      let questions = { ...childItem?.questions };
      questions[selectedQuestionUuid] = { q: question, a: value };
      childItem = { ...childItem, questions };
    }
    dispatch(addCampAnswersInSelectedChild(childItem));
  };

  const _renderInputWrtAnswerType = ({
    uuid,
    answerType,
    question,
    dropdownOptions,
    dropdownLabel,
  }) => {
    const selectedQuestionUuid = uuid;
    const answerBySelectedPageChild = bookingDetails?.selectedFamilyMembers[
      childPaginationPos
    ]?.questions
      ? bookingDetails?.selectedFamilyMembers[childPaginationPos]?.questions[
          selectedQuestionUuid
        ]?.a
      : "";

    switch (answerType) {
      case CAMP_Q_TYPES.TEXT:
        return (
          <input
            type="text"
            className="form-control"
            id="register"
            value={answerBySelectedPageChild}
            onChange={({ target: { value } }) =>
              _handleOnChangeInput({ value, selectedQuestionUuid, question })
            }
          />
        );
      case CAMP_Q_TYPES.TEXTAREA:
        return (
          <textarea
            className="form-control"
            rows="3"
            value={answerBySelectedPageChild}
            onChange={({ target: { value } }) =>
              _handleOnChangeInput({ value, selectedQuestionUuid, question })
            }
          />
        );
      case CAMP_Q_TYPES.DROPDOWN:
        return (
          <Dropdown
            onSelect={(value) =>
              _handleOnChangeInput({ value, selectedQuestionUuid, question })
            }
          >
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {typeof answerBySelectedPageChild === "undefined" ||
              answerBySelectedPageChild === ""
                ? dropdownLabel
                : answerBySelectedPageChild}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {dropdownOptions.map((option) => (
                <Dropdown.Item
                  key={option}
                  eventKey={option}
                  active={answerBySelectedPageChild === option}
                >
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      case CAMP_Q_TYPES.DATE:
        return (
          <AppDatePicker
            val={answerBySelectedPageChild}
            onChangeDate={(date) => {
              _handleOnChangeInput({
                value: moment(date).format("YYYY-MM-DD"),
                selectedQuestionUuid,
                question,
              });
            }}
            maxDate={"2999-12-31"}
          />
        );
      case CAMP_Q_TYPES.PHONE:
        return (
          <PhoneInput
            value={answerBySelectedPageChild}
            country="us"
            inputClass="tel-input w-100 font-size-16"
            onlyCountries={["us", "in"]}
            enableAreaCodes
            placeholder="Enter phone number"
            onChange={(event) =>
              _handleOnChangeInput({
                value: "+" + event,
                selectedQuestionUuid,
                question,
              })
            }
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
          />
        );
      case CAMP_Q_TYPES.NUMBER:
        return (
          <InputNumber
            min={0}
            value={answerBySelectedPageChild}
            onChange={(value) =>
              _handleOnChangeInput({ value, selectedQuestionUuid, question })
            }
          />
        );
    }
  };

  const _handlePagination = ({ type, selectedPos }) => {
    if (type === "prev") {
      if (childPaginationPos !== 0) {
        setChildPaginationPos((currentPos) => currentPos - 1);
      }
    } else if (type === "next") {
      if (
        bookingDetails?.selectedFamilyMembers.length !==
        childPaginationPos + 1
      ) {
        setChildPaginationPos((currentPos) => currentPos + 1);
      }
    } else {
      setChildPaginationPos(selectedPos);
    }
  };

  if (campQuestions?.length) {
    return (
      <>
        <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
          Camp Questions
        </p>
        <div className="row row-cols-1 row-cols-md-2 mb-3">
          <div>
            <p>
              Provide information for camp participant:{" "}
              {selectedChild?.firstName} {selectedChild?.lastName}
            </p>
            {campQuestions?.map(
              ({
                uuid,
                question,
                answerType,
                dropdownOptions,
                dropdownLabel,
                isRequired,
                helperText,
              }) => {
                return (
                  <div key={uuid}>
                    <div className="card mb-4 rounded-3 shadow-sm bg-light">
                      <div className="card-body">
                        <ul className="list-unstyled my-0">
                          <label className="form-label">
                            {question}
                            {isRequired && (
                              <span className="form-label required">
                                {" "}
                                <span style={{ color: "#c82638" }}>*</span>
                              </span>
                            )}
                          </label>
                          <br />
                          {helperText && (
                            <label className="label-helper-text">
                              {`(${helperText})`}
                            </label>
                          )}
                          {_renderInputWrtAnswerType({
                            uuid,
                            answerType,
                            question,
                            dropdownOptions,
                            dropdownLabel,
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
            {bookingDetails?.selectedFamilyMembers.length > 1 && (
              <>
                <p style={{ color: "#c82638" }}>
                  Complete questions for other participating campers
                </p>
                <Pagination>
                  <Pagination.First
                    onClick={() => _handlePagination({ type: "prev" })}
                  >
                    Previous
                  </Pagination.First>
                  {bookingDetails?.selectedFamilyMembers?.map((fMem, index) => (
                    <Pagination.Item
                      key={fMem.uuid}
                      active={selectedChildId === fMem?.uuid}
                      onClick={() => _handlePagination({ selectedPos: index })}
                      className="pagination-active"
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Last
                    onClick={() => _handlePagination({ type: "next" })}
                  >
                    Next
                  </Pagination.Last>
                </Pagination>
              </>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}

export default CampQuestions;
