import React from "react";
import moment from "moment";
import { AddToCalendarButton } from "add-to-calendar-button-react";

export default function AppAddToCalendar({
  location,
  timeZone,
  startDate,
  endDate,
  name,
}) {
  return (
    <>
      <AddToCalendarButton
        name={name}
        startDate={(startDate ? moment(startDate) : moment()).format(
          "YYYY-MM-DD"
        )}
        startTime={(startDate ? moment(startDate) : moment()).format("HH:mm")}
        endDate={(endDate ? moment(endDate) : moment()).format("YYYY-MM-DD")}
        endTime={(endDate ? moment(endDate) : moment()).format("HH:mm")}
        timeZone={timeZone}
        location={location}
        options={["Apple", "Google", "Yahoo", "iCal", "Outlook.com"]}
        listStyle="modal"
      />

      {/* <div>
              <label className="font-size-18">
                Add To Calendar
              </label>
              <div>
                <BsApple size={35} className="me-2" />
                <BsGoogle size={35} className="me-2" />
                <SiMicrosoftoutlook size={35} className="me-2" />
                <TbBrandYahoo size={35} className="me-2" />
              </div>
            </div> */}
    </>
  );
}
