import { useLocation } from "react-router-dom";

const SignUpSignInHeaderTexts = () => {
  const { pathname } = useLocation();
  const path = pathname.split("/")[1];
  console.log("path2", path);

  let headerMain = "Sign in or create a CoachFirst account",
    subHeader = "Existing CoachFirst customers please sign in",
    description =
      "If you have an account with us please sign in using your email address or phone number.";

  if (path === "login") {
    return (
      <>
        <h1 className="text-center pb-4">{headerMain}</h1>
        <div className="col-md-8 col-lg-6 col-xl-6 m-3 text-center">
          <h5>{subHeader}</h5>
          <p>{description}</p>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default SignUpSignInHeaderTexts;
