import React from "react";
import { getInitials } from "../../../utility/helperFunctions";
import { IMAGE_URL } from "../../../utility/constants";

const TrainerDetails = ({ trainerDetails }) => {
  if (trainerDetails) {
    return (
      <div className="_clientProDiv">
        <span className="_proPic">
          {trainerDetails?.imageUrl ? (
            <img src={IMAGE_URL + trainerDetails?.imageUrl} alt="" />
          ) : (
            <span className="avName">
              <span className="proName">
                {getInitials(
                  trainerDetails?.firstName,
                  trainerDetails?.lastName
                )}
              </span>
            </span>
          )}
        </span>
        <div className="_clCont">
          <p className="_name _title-2">{trainerDetails?.trainerName}</p>
          <p className="_info">{trainerDetails?.email}</p>
        </div>
      </div>
    );
  }
  return null;
};

export default TrainerDetails;
