import React, { Fragment } from "react";

const BookingSteps = ({ step }) => (
  <div className="container d-flex justify-content-center align-items-center mb-4">
    <div className="progresses">
      {Array.from(Array(4), (e, i) => (
        <Fragment key={i}>
          <div className="steps">
            {step - 1 > i ? (
              <i className="fa fa-check" />
            ) : (
              <span className="font-weight-bold">{i + 1}</span>
            )}
          </div>
          {i !== 3 && <span className="line" />}
        </Fragment>
      ))}
    </div>
  </div>
);

export default BookingSteps;
