import React, {useEffect} from "react";
import {Container, Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
  getAthleteProfileDetailsAction,
  showShareModal,
} from "../../../redux/actions/athleteProfileActions";
import useQuery from "../../../hooks/useQuery";
import Tabs from "./components/Tabs";
import {getInitials} from "../../../utility/helperFunctions";
import {IoIosShareAlt} from "react-icons/io";
import {athleteBanner, CFLogoSvg} from "../../../assets/imageDir";
import ProfileShareModal from "./components/ProfileShareModal";
import Lottie from "react-lottie";
import {defaultLoaderOptions} from "../../../common/config";
import {ATHLETE_PROFILE_SHARE_MODAL_TYPES} from "./utils";

const AthleteProfile = () => {
  const dispatch = useDispatch();
  const fmId = useQuery().get("fmId");
  const id = useQuery().get("id");
  const videoId = useQuery().get("videoId");
  const companyId = useQuery().get("companyId");
  const authDetails = useSelector((state) => state.auth);
  const authLoading = authDetails.authLoading.status || false
  console.log("authDetails", authDetails);
  const {details, loading} = useSelector((state) => state.athleteProfile) || {};
  const isNoData =
    details?.athlete?.firstName === undefined ||
    details?.athlete?.lastName === undefined;

  useEffect(() => {
    if (fmId != null) {
      dispatch(
        getAthleteProfileDetailsAction(
          fmId,
          companyId,
          videoId,
          "fm",
          authLoading
        )
      );
    }
    if (id != null) {
      dispatch(
        getAthleteProfileDetailsAction(
          id,
          companyId,
          videoId,
          "pm",
          authLoading
        )
      );
    }
  }, [fmId, id, videoId, authDetails?.authDetails]);

  if (loading) {
    return <Lottie options={defaultLoaderOptions} height={200} width={200} />;
  }

  return (
    <div className="Home">
      <div className="athlete banner-container">
        <div className="CFLogoSvg_container">
          <img src={CFLogoSvg} alt="CFLogoSvg" className="CFLogoSvg_logo" />
          <p className="logo_para">Powered by CoachFirst</p>
        </div>
        <img
          src={athleteBanner}
          alt="athleteBanner"
          className="background_image"
        />
      </div>
      {isNoData && (
        <Container className="profile-details d-flex justify-content-center align-items-center">
          <p className="text _text-theme font-size-20 font-italic ">
            {details?.message || "placeholder_error"}
          </p>
        </Container>
      )}
      {!isNoData && (
        <>
          <Container className="profile-details">
            <div className="left">
              <div className="profilePic">
                {details?.athlete?.imgUrl ? (
                  <img src={details?.athlete?.imgUrl} alt="profile pic" />
                ) : (
                  <span className="avName">
                    <span className="proName">
                      {getInitials(
                        details?.athlete?.firstName,
                        details?.athlete?.lastName
                      )}
                    </span>
                  </span>
                )}
              </div>
              <div className="details">
                <h2 className="heading">
                  {details?.athlete?.firstName} {details?.athlete?.lastName}
                </h2>
                <h4 className="sub-head">Athlete Profile</h4>
              </div>
            </div>
            <div className="right">
              <Button
                variant="info"
                className="btn btn-primary"
                onClick={() =>
                  dispatch(
                    showShareModal(
                      ATHLETE_PROFILE_SHARE_MODAL_TYPES.PROFILE,
                      location.href,
                      `${details?.athlete?.firstName} ${details?.athlete?.lastName}'s Profile`
                    )
                  )
                }
              >
                <IoIosShareAlt size={20} className="mr-1" />
                Share
              </Button>
            </div>
          </Container>
          <Tabs data={details} />
          <ProfileShareModal />
        </>
      )}
    </div>
  );
};

AthleteProfile.route = "athlete-profile";
export default AthleteProfile;
