export const AUTH_USER = "AUTH_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const SESSION_TYPE = "SESSION_TYPE";
export const ADD_TRAINER = "ADD_TRAINER";
export const ADD_SERVICE = "ADD_SERVICE";
// export const GET_SERVICES = "GET_SERVICES";
// export const GET_CLIENTS = "GET_CLIENTS";
export const ADD_COUPON_CODE = "ADD_COUPON_CODE";
export const GET_COMPANY_DATA = "GET_COMPANY_DATA";
export const ADD_TRAINING_TIME = "ADD_TRAINING_TIME";
export const CONFIRM_BOOKING = "CONFIRM_BOOKING";
export const SHOW_AUTH_LOAD = "SHOW_AUTH_LOAD";
export const CLEAR_AUTH_DATA = "CLEAR_AUTH_DATA";
export const NOTIFICATION_FETCHING = "NOTIFICATION_FETCHING";
export const NOTIFICATION_FETCH_COMPLETE = "NOTIFICATION_FETCH_COMPLETE";
export const DEFAULT_BOOKING_PAGE = "DEFAULT_BOOKING_PAGE";
export const REDIRECT_PUBLIC_USER = "REDIRECT_PUBLIC_USER";
export const CLEAR_PUBLIC_USER = "CLEAR_PUBLIC_USER";
export const CLEAR_COMPANY_DATA = "CLEAR_COMPANY_DATA";
export const ADD_CLASS_BOOKING_DETAILS = "ADD_CLASS_BOOKING_DETAILS";
export const ADD_SELECTED_PACKAGE = "ADD_SELECTED_PACKAGE";
export const CLEAR_CLASS_BOOKING_DETAILS = "CLEAR_CLASS_BOOKING_DETAILS";
export const SHOW_COMPANY_LOAD = "SHOW_COMPANY_LOAD";
export const PUBLIC_BRANCH_CHANGE = "PUBLIC_BRANCH_CHANGE";
export const CLEAR_SERVICE = "CLEAR_SERVICE";
export const CLEAR_TRAINER = "CLEAR_TRAINER";
export const CLEAR_TRAINING_TIME = "CLEAR_TRAINING_TIME";
export const PUBLIC_APPOINTMENT_BOOKING = "PUBLIC_APPOINTMENT_BOOKING";
export const CLEAR_APPOINTMENT_DETAILS = "CLEAR_APPOINTMENT_DETAILS";
export const ADD_COMPANY_DATA = "ADD_COMPANY_DATA";
export const HIDE_COMPANY_LOAD = "HIDE_COMPANY_LOAD";
export const SET_SELECTED_FAMILY_MEMBERS = "SET_SELECTED_FAMILY_MEMBERS";
export const SET_SELECTED_FAMILY_MEMBER_SESSION =
  "SET_SELECTED_FAMILY_MEMBER_SESSION";
// export const SET_CAMP_QUESTIONS = "SET_CAMP_QUESTIONS";
export const ADD_CAMP_ANSWERS_IN_SELECTED_CHILD =
  "ADD_CAMP_ANSWERS_IN_SELECTED_CHILD";
export const UPDATE_FAMILY_MEMBERS = "UPDATE_FAMILY_MEMBERS";
export const UPDATE_COMPANY_WAIVER_SIGNED = "UPDATE_COMPANY_WAIVER_SIGNED";
export const UPDATE_COMPANY_MEMBER_TNC = "UPDATE_COMPANY_MEMBER_TNC";

export const BOOKING_SESSION_SET_TIME = "BOOKING_SESSION_SET_TIME";
export const BOOKING_SESSION_SET_DATE = "BOOKING_SESSION_SET_DATE";

//! checkin
export const CHECKIN_CLASS_DETAILS_FETCH_COMPLETED =
  "CHECKIN_CLASS_DETAILS_FETCH_COMPLETED";
export const CHECKIN_MODAL_HIDE = "CHECKIN_MODAL_HIDE";
export const CHECKIN_SEARCH_MEMBER_STARTED = "CHECKIN_SEARCH_MEMBER_STARTED";
export const CHECKIN_SEARCH_MEMBER_FINISHED = "CHECKIN_SEARCH_MEMBER_FINISHED";
export const CHECKIN_MEMBER_TO_CLASS_STARTED =
  "CHECKIN_MEMBER_TO_CLASS_STARTED";
export const CHECKIN_MEMBER_TO_CLASS_FINISHED =
  "CHECKIN_MEMBER_TO_CLASS_FINISHED";
export const CHECKIN_DROP_IN_SET_INPUT_EMAIL_OR_PHONE =
  "CHECKIN_DROP_IN_SET_INPUT_EMAIL_OR_PHONE";
export const CHECKIN_DROP_IN_MEMBERS_STARTED =
  "CHECKIN_DROP_IN_MEMBERS_STARTED";
export const CHECKIN_DROP_IN_MEMBERS_FINISHED =
  "CHECKIN_DROP_IN_MEMBERS_FINISHED";
export const CHECKIN_SHOW_SENT_REGISTRATION_EMAIL =
  "CHECKIN_SHOW_SENT_REGISTRATION_EMAIL";
export const CHECKIN_WAIVER_SHOW_MODAL = "CHECKIN_WAIVER_SHOW_MODAL";
export const CHECKIN_WAIVER_SIGN_IN_STARTED = "CHECKIN_WAIVER_SIGN_IN_STARTED";
export const CHECKIN_WAIVER_SIGN_IN_FINISHED =
  "CHECKIN_WAIVER_SIGN_IN_FINISHED";
export const CHECKIN_DROP_IN_WAIVER_SET_SELECTED_MEMBERS =
  "CHECKIN_DROP_IN_WAIVER_SET_SELECTED_MEMBERS";

//! signup
export const SIGNUP_CHANGE_SCREEN = "SIGNUP_CHANGE_SCREEN";
export const SIGNUP_SET_INITIAL_DETAILS_MEM_NAME =
  "SIGNUP_SET_INITIAL_DETAILS_MEM_NAME";
export const SIGNUP_SET_INITIAL_DETAILS_FM = "SIGNUP_SET_INITIAL_DETAILS_FM";
export const SIGNUP_ON_CHANGE_FIELDS_SINGLE = "SIGNUP_ON_CHANGE_FIELDS_SINGLE";
export const SIGNUP_RESET = "SIGNUP_RESET";
export const SIGNUP_SET_FIELDS_MULTIPLE = "SIGNUP_SET_FIELDS_MULTIPLE";
export const SIGNUP_FM_ADD_FIELD_SINGLE = "SIGNUP_FM_ADD_FIELD_SINGLE";
export const SIGNUP_START_LOADING = "SIGNUP_START_LOADING";
export const SIGNUP_FM_CHANGE_FIELDS_SINGLE = "SIGNUP_FM_CHANGE_FIELDS_SINGLE";
export const SIGNUP_FM_DELETE_FIELD_SINGLE = "SIGNUP_FM_DELETE_FIELD_SINGLE";
export const SIGNUP_SET_SUCCESS = "SIGNUP_SET_SUCCESS";

//! athlete profile
export const ATHLETE_PROFILE_FETCH_COMPLETED =
  "ATHLETE_PROFILE_FETCH_COMPLETED";
export const ATHLETE_PROFILE_FETCH_STARTED = "ATHLETE_PROFILE_FETCH_STARTED";
export const ATHLETE_PROFILE_SHARE_MODAL_SHOW =
  "ATHLETE_PROFILE_SHARE_MODAL_SHOW";
export const ATHLETE_PROFILE_SHARE_MODAL_HIDE =
  "ATHLETE_PROFILE_SHARE_MODAL_HIDE";
