import moment from "moment";
import * as animationData from "../assets/roundLoader.json";
import credit_cards_uri from "./credit_cards_uri.json";

const defaultLoaderOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const genderArr = [
  "-- select --",
  "Male",
  "Female",
  "Other",
  "Prefer not to say",
];

const calendarsOptions = [
  {
    id: "0",
    name: "1:1 to session",
    bgColor: "#c72638",
    borderColor: "#c72638",
    color: "white",
  },
  {
    id: "1",
    name: "Class",
    bgColor: "#50a5f1",
    borderColor: "#50a5f1",
    color: "white",
  },
];

const registrationBusinessSpecialty = [
  { value: "Baseball" },
  { value: "Basketball" },
  { value: "Bodybuilding" },
  { value: "Field Hockey" },
  { value: "Fitness" },
  { value: "Football" },
  { value: "Golf" },
  { value: "Gymnastics" },
  { value: "Ice Hockey" },
  { value: "Lacrosse" },
  { value: "Martial Arts" },
  { value: "Massage" },
  { value: "Nutrition" },
  { value: "Personal Training" },
  { value: "Pilates" },
  { value: "Soccer" },
  { value: "Softball" },
  { value: "Swimming" },
  { value: "Tae Kwon Do" },
  { value: "Tennis" },
  { value: "Volleyball" },
  { value: "Wellness" },
  { value: "Yoga" },
  { value: "Other" },
];

const campStepsArr = [
  {
    title: "Primary Member Registration",
  },
  {
    title: "Add Family Members",
  },
  {
    title: "E-mail Verification",
  },
  {
    title: "Book A Service",
  },
  {
    title: "Payment",
  },
];

const calendarDateRange = {
  // start: new Moment(),
  // end: Moment().add(7, "d"),
  start: new moment(),
  end: new moment().add(6, "d"),
  range: [],
};

const creditCardAssets = [
  {
    cardBrandFull: "American Express",
    cardBrand: "amex",
    // digitsLength: 15,
    uri: credit_cards_uri.American_Express,
  },
  {
    cardBrandFull: "Diners Club",
    cardBrand: "dinersclub",
    uri: credit_cards_uri.Diners_Club,
  },
  {
    cardBrandFull: "Discover",
    cardBrand: "discover",
    uri: credit_cards_uri.Discover,
  },
  {
    cardBrandFull: "Jcb",
    cardBrand: "jcb",
    uri: credit_cards_uri.Jcb,
  },
  {
    cardBrandFull: "maestro",
    cardBrand: "maestro",
    uri: credit_cards_uri.maestro,
  },
  {
    cardBrandFull: "mastercard",
    cardBrand: "mastercard",
    uri: credit_cards_uri.mastercard,
  },
  {
    cardBrandFull: "unionpay",
    cardBrand: "unionpay",
    uri: credit_cards_uri.unionpay,
  },
  {
    cardBrandFull: "visa",
    cardBrand: "visa",
    uri: credit_cards_uri.visa,
  },
];

const BOOKING_TYPES = Object.freeze({
  CLASS: "class",
  APPOINTMENT: "appointment",
});

const ANT_DESIGN_NOTIFICATION_TYPES = Object.freeze({
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
});
const PUBNUB_KEYS = {
  publishKey: "pub-c-bf2a5cb1-cf92-41f9-b72a-bb989d67896e",
  subscribeKey: "sub-c-0d0e93e0-0e39-11eb-a3e5-1aef4acbe547",
  userId: "clientAddedToClassCoachFirst",
};
export {
  defaultLoaderOptions,
  genderArr,
  calendarsOptions,
  registrationBusinessSpecialty,
  campStepsArr,
  calendarDateRange,
  creditCardAssets,
  BOOKING_TYPES,
  ANT_DESIGN_NOTIFICATION_TYPES,
  PUBNUB_KEYS
};
