import React from "react";
import moment from "moment";

export default function AppDatePicker({ maxYear, onChangeDate, val, maxDate }) {
  return (
    <input
      className="form-control"
      type="date"
      value={val}
      id="date-input"
      onChange={(e) => {
        onChangeDate(e.target.value);
      }}
      max={
        maxYear ? moment().subtract(maxYear, "Y").format("YYYY-MM-DD") : maxDate
      }
    />
  );
}
