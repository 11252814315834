import { Auth } from "aws-amplify";
import {
  AUTH_USER,
  CLEAR_AUTH_DATA,
  GET_COMPANY_DATA,
  SHOW_AUTH_LOAD,
  CLEAR_COMPANY_DATA,
  SHOW_COMPANY_LOAD,
  ADD_COMPANY_DATA,
  HIDE_COMPANY_LOAD,
  UPDATE_FAMILY_MEMBERS,
  UPDATE_COMPANY_WAIVER_SIGNED,
  SIGNUP_RESET,
  UPDATE_COMPANY_MEMBER_TNC,
} from "../actionTypes";

import { AxiosHelperPortal } from "../../utility/helperFunctions";
import { BASE_URL_WEB } from "../../utility/constants";

export const loginUser = (
  { email, password },
  flowType = "USER_SRP_AUTH",
  login_token = null
) => {
  console.log(email);
  console.log(password);
  return async (dispatch) => {
    dispatch({ type: SHOW_AUTH_LOAD, payload: { status: true, err: "" } });
    // flowType = 'CUSTOM_AUTH'
    // login_token = '8d7a279dbbe618b26134a9adb5d18556'
    // email = 'f60ecffbb1683a61700bc742796bfbec'
    try {
      Auth.configure({
        authenticationFlowType: flowType, //USER_SRP_AUTH
      });
      console.log("email", email);
      Auth.signIn(email, password)
        .then(async (user) => {
          console.log("initial response", user);
          if (login_token === null) {
            dispatch({
              type: AUTH_USER,
              authDetails: user,
              payload: { status: false, err: "" },
            });
          } else {
            if (user.challengeName === "CUSTOM_CHALLENGE") {
              // to send the answer of the custom challenge
              Auth.sendCustomChallengeAnswer(user, login_token)
                .then((user) => {
                  console.log("challenge response", user);
                  dispatch({
                    type: AUTH_USER,
                    authDetails: user,
                    payload: { status: false, err: "" },
                  });
                })
                .catch((err) => console.log(err));
            } else {
              console.log(user);
            }
          }
          dispatch({
            type: SIGNUP_RESET,
          });
          //console.log("signn in details: ", response);
          //const attributes = response.attributes;

          //if (attributes["custom:userType"].toLowerCase() === "user") {
          //if (true) {

          // dispatch({
          //   type: AUTH_USER,
          //   authDetails: response,
          //   payload: {
          //     status: !(attributes["custom:userType"].toLowerCase() === "user"), // when user cutom type is not user authLoading will not stop the loader it will stop when user details for memvber is fetched
          //     err: "",
          //   },
          // });
        })
        .catch((error) => {
          console.log("error login", error);
          dispatch({
            type: SHOW_AUTH_LOAD,
            payload: { status: false, err: error },
          });
        });
    } catch (error) {
      // dispatch({
      //   type: SHOW_AUTH_LOAD,
      //   payload: { status: false, err: error },
      // });
      console.log("err", error);
    }
  };
};

const companyData = async () => {
  const response = await AxiosHelperPortal(BASE_URL_WEB + "home-user?", "get");
  return response.data;
};

export const authCompanyData = () => {
  return (dispatch) => {
    companyData().then((data) => {
      dispatch({
        type: GET_COMPANY_DATA,
        payload: data,
      });
    });
  };
};

export const logoutUser = () => {
  localStorage.clear();
  return (dispatch) => {
    dispatch({
      type: CLEAR_AUTH_DATA,
    });
  };
};

export const publicCompanyData = (data) => {
  return {
    type: GET_COMPANY_DATA,
    payload: data,
  };
};

export const clearCompanyData = () => {
  return {
    type: CLEAR_COMPANY_DATA,
  };
};

export const showCompanyDataLoad = () => {
  return {
    type: SHOW_COMPANY_LOAD,
  };
};
export const hideCompanyDataLoad = () => {
  return {
    type: HIDE_COMPANY_LOAD,
  };
};

export const addCompanyData = (state) => {
  return {
    type: ADD_COMPANY_DATA,
    payload: state,
  };
};

export const updateFamilyMembers = (payload) => {
  return {
    type: UPDATE_FAMILY_MEMBERS,
    payload,
  };
};

export const updateCompanyWaiverSigned = () => {
  return {
    type: UPDATE_COMPANY_WAIVER_SIGNED,
  };
};
export const updateCompanyMemberTnc = () => {
  return {
    type: UPDATE_COMPANY_MEMBER_TNC,
  };
};
