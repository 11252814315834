import React from "react";
import {Button} from "react-bootstrap";
import {
  // openInNewTab,
  showToast,
} from "../../../../utility/helperFunctions";
import {
  FaFacebook,
  FaWhatsapp,
  // FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import {FaXTwitter} from "react-icons/fa6";
import {ANT_DESIGN_NOTIFICATION_TYPES} from "../../../../common/config";
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "antd";
import {ATHLETE_PROFILE_SHARE_MODAL_TYPES} from "../utils";
import {hideShareModal} from "../../../../redux/actions/athleteProfileActions";

const ProfileShare = ({url, title}) => {
  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(url);
      showToast[ANT_DESIGN_NOTIFICATION_TYPES.SUCCESS]("Link Copied");
    } catch (err) {
      console.error("Failed to copy text:", err);
      alert("Something Went Wrong Try Again Later!!");
    }
  };

  const encodedURL = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);

  const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`;
  const twitterShare = `https://twitter.com/intent/tweet?url=${encodedURL}&text=${encodedTitle}`;
  const linkedinShare = `https://www.linkedin.com/shareArticle?url=${encodedURL}&title=${encodedTitle}`;
  const whatsappShare = `https://api.whatsapp.com/send?text=${encodedTitle}%20${encodedURL}`;
  // const instagramShare = `https://www.instagram.com/?url=${encodedURL}`; // Note: Instagram doesn't support direct URL sharing via web

  return (
    <div className="shareBtnAthlete">
      <div className="topSec">
        <h4 className="shareHeading">Share link via</h4>
        <ul className="shareUl">
          <li className="shareLI">
            <a href={facebookShare} target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
          </li>
          <li className="shareLI">
            <a href={twitterShare} target="_blank" rel="noopener noreferrer">
              <FaXTwitter />
            </a>
          </li>
          {/* <li className="shareLI">
            <a href={instagramShare} target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
          </li> */}
          <li className="shareLI">
            <a href={whatsappShare} target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />
            </a>
          </li>
          <li className="shareLI">
            <a href={linkedinShare} target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </li>
        </ul>
      </div>
      <div className="bottomSec">
        <h4 className="shareHeading">Copy Link</h4>
        <p className="clipboardLink">
          <span className="text-nowrap">{url}</span>
        </p>
        <Button className="text-nowrap w-100" onClick={handleClick}>
          Copy to clipboard
        </Button>
      </div>
    </div>
  );
};

const ProfileShareModal = () => {
  const {isShow, type, url, title} =
    useSelector((state) => state.athleteProfile?.modal) || {};
  const dispatch = useDispatch();
  return (
    <Modal
      title={
        type === ATHLETE_PROFILE_SHARE_MODAL_TYPES.PROFILE && (
          <h2 className="modalHead">Share Athlete Profile</h2>
        )
      }
      visible={isShow}
      footer={null}
      className="custom-modal-class"
      style={{top: 20}}
      width={500}
      centered
      onCancel={() => dispatch(hideShareModal())}
    >
      <ProfileShare url={url} title={title} />
    </Modal>
  );
};

export default ProfileShareModal;
