import success_loader from "../../../assets/success_loader.json";

export const getBookingConfirmationPageData = ({
  order,
  service,
  subscription,
  member,
  membership_pack,
  ptRemaining,
  doNotShowOrder,
  bookedWithCredits,
  isUnlimited,
  isFromCheckIn,
}) => {
  console.log("getBookingConfirmationPageData response ->", {
    order,
    service,
    subscription,
    member,
    membership_pack,
    ptRemaining,
    doNotShowOrder,
    bookedWithCredits,
    isUnlimited,
    isFromCheckIn,
  });
  try {
    // const isSquare = service?.payment_method === "square";
    const isClass = service?.typename !== "appointment";
    let participants = [
      `${service?.memberFirstName || ""} ${service?.memberLastName || ""}`,
    ];
    let coaches = [service?.trainerName];
    if (isClass) {
      participants = service?.familyMembers?.map((e) =>
        e?.isParent
          ? `${member?.firstName || ""} ${member?.lastName || ""}`
          : `${e?.firstName || ""} ${e?.lastName || ""}`
      );
      coaches = service?.selectedTrainers?.map((e) => e?.trainerName);
    }
    const isOrder = typeof order !== "undefined" || order !== null;
    const response = {
      basic: {
        startDateTime: service?.start_branch_time,
        endDateTime: service?.end_branch_time,
        serviceName: service?.serviceName,
        coaches,
        participants,
        location: service?.branchName,
        bookingType: isClass
          ? order?.isCamp
            ? "camp"
            : "class"
          : "appointment",
        timezone: service?.timezone,
      },
      remaining: ptRemaining,
      membership_pack: membership_pack,
      doNotShowOrder: doNotShowOrder,
      bookedWithCredits: bookedWithCredits,
      isUnlimited: isUnlimited,
      isFromCheckIn,
      order: {
        // servicePrice: service?.price,
        // cardProcessingFee:
        //   isSquare &&
        //   order?.square_payment_response?.app_fee_money?.amount / 100,
        // platformFee: isSquare && (service?.price * 2.99) / 100 + 30 / 100,
        isShow: !doNotShowOrder,
        total: parseFloat(
          isOrder ? order?.price : subscription?.price
        )?.toFixed(2), // price//total price
        // orderId: service?.orderId,
        // receiptUrl: isSquare && order?.receipt_url,
      },
    };
    console.log("getBookingConfirmationPageData modified response", response);
    return response;
  } catch (error) {
    console.log("error", error, error.response);
  }
};

export const postBookingLottieDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: success_loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
