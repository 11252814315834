import {
  ATHLETE_PROFILE_FETCH_COMPLETED,
  ATHLETE_PROFILE_FETCH_STARTED,
  ATHLETE_PROFILE_SHARE_MODAL_HIDE,
  ATHLETE_PROFILE_SHARE_MODAL_SHOW,
} from "../actionTypes";
import { getAthleteProfileDetails } from "../../pages/common/AthleteProfile/utils";

export const getAthleteProfileDetailsAction =
  (id, companyId, videoId, type,authLoading) => async (dispatch) => {
    dispatch({ type: ATHLETE_PROFILE_FETCH_STARTED });
    const response = await getAthleteProfileDetails(
      id,
      companyId,
      videoId,
      type,
      authLoading
    );
    console.log("response", response);
    if (response.status === 200) {
      dispatch({
        type: ATHLETE_PROFILE_FETCH_COMPLETED,
        payload: response.data,
      });
      return;
    }
  };

export const showShareModal = (type, url, title) => {
  return {
    type: ATHLETE_PROFILE_SHARE_MODAL_SHOW,
    payload: { type, url, title },
  };
};

export const hideShareModal = () => {
  return {
    type: ATHLETE_PROFILE_SHARE_MODAL_HIDE,
  };
};
