import {
  ATHLETE_PROFILE_FETCH_COMPLETED,
  ATHLETE_PROFILE_FETCH_STARTED,
  ATHLETE_PROFILE_SHARE_MODAL_HIDE,
  ATHLETE_PROFILE_SHARE_MODAL_SHOW,
} from "../actionTypes";

const INITIAL_STATE = {
  details: null,
  loading: false,
  modal: {
    isShow: false,
    type: "", //ATHLETE_PROFILE_SHARE_MODAL_TYPES,
    url: "",
    title: "",
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ATHLETE_PROFILE_FETCH_COMPLETED:
      return {
        ...state,
        details: action.payload,
        loading: false,
      };
    case ATHLETE_PROFILE_FETCH_STARTED:
      return {
        ...state,
        loading: true,
      };
    case ATHLETE_PROFILE_SHARE_MODAL_SHOW:
      return {
        ...state,
        modal: {
          isShow: true,
          type: action.payload.type,
          url: action.payload.url,
          title: action.payload.title,
        },
      };
    case ATHLETE_PROFILE_SHARE_MODAL_HIDE:
      return {
        ...state,
        modal: INITIAL_STATE.modal,
      };

    default:
      return state;
  }
};

export default reducer;
