import React from "react";
import { calendar, placeholder } from "../../../assets/imageDir";
import { BOOKING_TYPES } from "../../../common/config";
import moment from "moment";
import { tConvert } from "../../../utility/helperFunctions";

const BookingDateTimeUI = ({
  bookingType,
  bookingDetails,
  repeatIdClasses,
  isRepeatClass,
}) => {
  const startEndTimeBasedOnRepeatClasses = {
    startDateTime:
      bookingType === BOOKING_TYPES.APPOINTMENT
        ? `${moment(bookingDetails?.trainingTime?.date).format(
            "MMMM Do, YYYY"
          )} ${tConvert(bookingDetails?.trainingTime?.time)}`
        : isRepeatClass
        ? moment(repeatIdClasses[0]?.start_branch_time).format(
            "MMMM Do, YYYY hh:mm A"
          )
        : //! if normal class
          moment(bookingDetails?.classDetails?.start_branch_time).format(
            "MMMM Do, YYYY hh:mm A"
          ),
    endRepeatDateTime:
      isRepeatClass &&
      moment(
        repeatIdClasses[repeatIdClasses?.length - 1]?.start_branch_time
      ).format("MMMM Do, YYYY hh:mm A"),
  };

  return (
    <>
      <div className="_timeListDiv mt-4">
        <div className="_ctimeDiv">
          <input type="time" className="form-control" />
        </div>
        <div className="_finalDate mt-4">
          <span className="_icon">
            <img src={placeholder} alt="" />
          </span>
          {bookingType === BOOKING_TYPES.APPOINTMENT ? (
            <p>{bookingDetails?.trainer?.branchName}</p>
          ) : (
            <p>{bookingDetails?.classDetails?.branchName}</p>
          )}
        </div>
        <div className="_finalDate mt-4">
          <span className="_icon">
            <img src={calendar} alt="" />
          </span>

          <p>
            {startEndTimeBasedOnRepeatClasses?.startDateTime}{" "}
            {startEndTimeBasedOnRepeatClasses?.endRepeatDateTime && (
              <span>
                - {startEndTimeBasedOnRepeatClasses?.endRepeatDateTime}
              </span>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default BookingDateTimeUI;
