import {Table} from "antd";
import React from "react";
import {Card} from "react-bootstrap";
import {formatValue} from "../utils";
import moment from "moment-timezone";

const PerformanceTabData = ({data}) => {
  const _renderTableCol = () => [
    {
      title: "VALUE",
      key: "value",
      width: "70%",
      render: (_text, record) => {
        // console.log('record',record);
        // Check if value is a string
        if (typeof record?.value === "string") {
          return (
            <p className="font-size-16 font-weight-normal">
              {formatValue(record?.value, data?.fieldType)}
            </p>
          );
        } else {
          // Handle object with different properties
          // const { feet, inch, format, HH, MM, SS, hh } = record?.value || {};
          // let formattedValue = "";
          // if (format) {
          //   // If format exists, replace placeholders with actual values
          //   formattedValue = format
          //     .replace("HH", HH || "")
          //     .replace("MM", MM || "")
          //     .replace("SS", SS || "")
          //     .replace("hh", hh || "");
          // } else {
          //   // Concatenate feet and inch if they exist
          //   formattedValue = `${feet ? feet + " feet " : ""}${
          //     inch ? inch + " inch" : ""
          //   }`;
          // }
          return (
            <>
              <p className="font-size-16 font-weight-normal">
                {formatValue(record?.value, data?.fieldType)}
              </p>
            </>
          );
        }
      },
    },
    {
      title: "DATE",
      key: "date",
      width: "30%",
      render: (_text, record) => (
        <>
          <p className="font-size-13 font-weight-normal mt-2 check-in-list-email">
            {moment(record?.date).format("MM/DD/YYYY")}
          </p>
        </>
      ),
    },
  ];

  return (
    <div>
      <Card className="tablist-card">
        <Card.Body>
          {/* <div className="list-grp">
            <div className="list-items">
              <Card.Img variant="top" src="./assets/card-logo.jpg" />
              <div className="details">
                <h2>{data?.questionName}</h2>
                <p>Pensacola, FL</p>
                <span className="light">2023</span>
              </div>
              <span className="light">2023</span>
            </div>
            <div className="btn btn-primary">History</div>
          </div> */}
          {!!data?.answer?.length && (
            <Table
              scroll={{
                y: 300,
              }}
              columns={_renderTableCol()}
              dataSource={data?.answer ?? []}
              pagination={true}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PerformanceTabData;
